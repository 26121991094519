import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddPaymentGateway from "./AddPaymentGateway";
import EditPaymentGateway from "./EditPaymentGateway";
import DeletePaymentGateway from "./DeletePaymentGateway";
import { getPaymentGatewaysApi, addPaymentGatewayApi, updatePaymentGatewayApi, deletePaymentGatewayApi } from "networks";
import moment from "moment"; // for date formatting

const PaymentGatewayManagement = () => {
  const [gateways, setGateways] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editGatewayData, setEditGatewayData] = useState(null);
  const [deleteGatewayData, setDeleteGatewayData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addGatewayModal = useDisclosure();
  const toast = useToast();

  const fetchGatewaysData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPaymentGatewaysApi(); // Call API
      setGateways(response.data.data); // Assuming API returns data in response.data
    } catch (error) {
      toast({
        title: "Error fetching gateways.",
        description: "An error occurred while fetching payment gateways.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchGatewaysData();
  }, [fetchGatewaysData]);

  // Add a new payment gateway
  const handleAddGateway = async (newGateway) => {
    try {
      await addPaymentGatewayApi(newGateway); // Call API to add gateway
      fetchGatewaysData();
      toast({
        title: "Payment Gateway added.",
        description: "The payment gateway has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addGatewayModal.onClose();
    } catch (error) {
      toast({
        title: "Error adding gateway.",
        description: "An error occurred while adding the payment gateway.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Update an existing payment gateway
  const handleUpdateGateway = async (updatedGateway) => {
    try {
      await updatePaymentGatewayApi(updatedGateway); // Call API to update gateway
      fetchGatewaysData();
      toast({
        title: "Payment Gateway updated.",
        description: "The payment gateway has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditGatewayData(null);
    } catch (error) {
      toast({
        title: "Error updating gateway.",
        description: "An error occurred while updating the payment gateway.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a payment gateway
  const handleDeleteGateway = async (id) => {
    try {
      await deletePaymentGatewayApi(id); // Call API to delete gateway
      fetchGatewaysData();
      toast({
        title: "Payment Gateway deleted.",
        description: "The payment gateway has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDeleteGatewayData(null);
    } catch (error) {
      toast({
        title: "Error deleting gateway.",
        description: "An error occurred while deleting the payment gateway.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditGateway = (gateway) => {
    setEditGatewayData(gateway);
  };

  const handleDeleteGatewayModal = (gateway) => {
    setDeleteGatewayData(gateway);
  };

//   const filteredGateways = gateways.filter((gateway) =>
//     gateway.gateway_key.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     gateway.status.toLowerCase().includes(searchTerm.toLowerCase())
//   );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Payment Gateway Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addGatewayModal.onOpen}
          >
            Add Payment Gateway
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by key or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Payment Gateway Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Name</Th>
                      <Th>Key</Th>
                      <Th>Status</Th>
                      <Th>Created At</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {gateways.map((gateway) => (
                      <Tr key={gateway.id}>
                        <Td>{gateway.id}</Td>
                        <Td>{gateway.name}</Td>
                        <Td>{gateway.gateway_key}</Td>
                        <Td color={gateway.status === "Active" ? "green.500" : "red.500"}>
                          {gateway.status}
                        </Td>
                        <Td>{moment(gateway.created_at).format("YYYY-MM-DD HH:mm")}</Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditGateway(gateway)}
                            aria-label="Edit Gateway"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeleteGatewayModal(gateway)}
                            aria-label="Delete Gateway"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddPaymentGateway modal */}
      <AddPaymentGateway
        isOpen={addGatewayModal.isOpen}
        onClose={addGatewayModal.onClose}
        addNewGateway={handleAddGateway}
      />

      {/* EditPaymentGateway modal */}
      {editGatewayData && (
        <EditPaymentGateway
          isOpen={!!editGatewayData}
          onClose={() => setEditGatewayData(null)}
          updateGatewayProp={editGatewayData}
          updateGateway={handleUpdateGateway}
        />
      )}

      {/* DeletePaymentGateway modal */}
      {deleteGatewayData && (
        <DeletePaymentGateway
          isOpen={!!deleteGatewayData}
          onClose={() => setDeleteGatewayData(null)}
          deleteGatewayProp={deleteGatewayData}
          deleteGateway={handleDeleteGateway}
        />
      )}
    </Box>
  );
};

export default PaymentGatewayManagement;
