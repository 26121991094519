import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  Spinner,
  useToast,
  Card,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { getTransactionsApi } from "networks"; 
import { format } from "date-fns";
import { MdVisibility } from "react-icons/md";
import CheckTransaction from "./CheckTransaction";

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]); // Holds transaction data
  const [loading, setLoading] = useState(true); // Manages loading state
  const [searchTerm, setSearchTerm] = useState(""); // Search input state
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const [viewTransactionData, setViewTransactionData] = useState(null); // State for viewing a product
  const toast = useToast();
  const viewCheckTransactionModal = useDisclosure();

  const fetchTransactionsData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getTransactionsApi();
      setTransactions(response.data.data);
    } catch (error) {
      toast({
        title: "Error fetching transactions.",
        description: "An error occurred while fetching transactions.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchTransactionsData();
  }, [fetchTransactionsData]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "N/A";
    const date = new Date(timestamp);
    return format(date, "yyyy-MM-dd");
  };

  // Filter transactions based on search term
  const filteredTransactions = transactions.filter(
    (transaction) =>
      transaction.transaction_id
        .toString()
        .includes(searchTerm.toLowerCase()) ||
      transaction.user_name.toString().includes(searchTerm) ||
      transaction.transaction_status
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );
  const handleCheckTransaction = (transaction) => {
    setViewTransactionData(transaction); // Set the product to be viewed
    viewCheckTransactionModal.onOpen(); // Open the view product modal
  };
  const handleCloseTransactionModal=()=>{
    fetchTransactionsData()
    viewCheckTransactionModal.onClose()
  }
  
  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text
            color={textColor}
            fontSize={{ base: "lg", md: "2xl" }}
            ms="24px"
            fontWeight="700"
          >
            Transaction Management
          </Text>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by transaction ID, user ID or status..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <SearchIcon />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Transaction Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              {/* Wrap the TableContainer with Box for horizontal scroll */}
              <Box overflowX="auto">
                <TableContainer>
                  <Table variant="simple" size="md">
                    <Thead>
                      <Tr>
                        <Th>Transaction ID</Th>

                        <Th>User Name</Th>
                        <Th>User Phone</Th>
                        <Th>Payment ID</Th>
                        <Th>Order ID</Th>
                        <Th>Amount</Th>
                        <Th>Transaction Type</Th>
                        <Th>Created At</Th>
                        <Th>Modified At</Th>
                        <Th>Transaction Status</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredTransactions.map((transaction) => (
                        <Tr key={transaction.transaction_id}>
                          <Td>{transaction.transaction_id}</Td>
                          <Td>{transaction.user_name}</Td>
                          <Td>{transaction.user_phone_number}</Td>
                          <Td>{transaction.payment_id}</Td>
                          <Td>{transaction.order_id}</Td>
                          <Td>{transaction.amount}</Td>
                          <Td>{transaction.transaction_type}</Td>
                          <Td>{formatDate(transaction.created_at)}</Td>
                        <Td>{formatDate(transaction.modified_at)}</Td>
                          <Td
                            color={
                              transaction.transaction_status === "Success"
                                ? "green.500"
                                : "red.500"
                            }
                          >
                            {transaction.transaction_status}
                          </Td>
                          <Td>
                          <IconButton
                            icon={<MdVisibility />} // View product button
                            onClick={() => handleCheckTransaction(transaction)}
                            aria-label="View Product"
                            mr={2}
                            colorScheme="blue"
                            size="sm"
                          />
                          </Td>
                          
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Box>
            </Card>
          )}
        </Flex>
      </Flex>
      {viewTransactionData && (
        <CheckTransaction
          isOpen={viewCheckTransactionModal.isOpen}
          onClose={handleCloseTransactionModal}
          transaction={viewTransactionData} // Pass the product to the view modal
        />
      )}

    </Box>
  );
};

export default TransactionManagement;
