import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { addOTAApi } from 'networks';

const AddOTA = ({ isOpen, onClose, refreshOta }) => {
  const [otaData, setOtaData] = useState({ version: '', apk_file: null });
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOtaData({ ...otaData, [name]: value });
  };

  const handleFileChange = (e) => {
    setOtaData({ ...otaData, apk_file: e.target.files[0] });
  };

  const handleSubmit = async () => {
    if (!otaData.version || !otaData.apk_file) {
      toast({
        title: 'Error',
        description: 'Please enter version and select an APK file.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const formData = new FormData();
    formData.append('version', otaData.version);
    formData.append('apk_file', otaData.apk_file);
    try {
      await addOTAApi(formData);
      toast({
        title: 'OTA Added',
        description: 'OTA record added successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      refreshOta();
      onClose();
      setOtaData({ version: '', apk_file: null });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to add OTA record.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add OTA</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Version</FormLabel>
            <Input
              type="text"
              name="version"
              placeholder="Enter OTA version"
              value={otaData.version}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl mb={3}>
            <FormLabel>APK File</FormLabel>
            <Input type="file" accept=".apk" onChange={handleFileChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddOTA;
