import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  TableContainer,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Stack,
  Badge,
} from "@chakra-ui/react";
import Select from "react-select"; // Import react-select
import { MdAdd, MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import AddSpecialDiscount from "./AddSpecialDiscount";
import EditSpecialDiscount from "./EditSpecialDisount";
import DeleteSpecialDiscount from "./DeleteSpecialDisount";
import {
  getSpecialDiscountsApi,
  addSpecialDiscountApi,
  updateSpecialDiscountApi,
  deleteSpecialDiscountApi,
} from "networks"; // Import API functions

const SpecialDiscountManagement = () => {
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editDiscountData, setEditDiscountData] = useState(null);
  const [deleteDiscountData, setDeleteDiscountData] = useState(null);
  const [viewDiscountData, setViewDiscountData] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedCategories, setSelectedCategories] = useState([]); // State for selected categories
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addDiscountModal = useDisclosure();
  const viewDiscountModal = useDisclosure();
  const toast = useToast();

  // Fetch discounts data
  const fetchDiscountsData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getSpecialDiscountsApi(); // Call API
      setDiscounts(response.data.data); // Set discounts data
    } catch (error) {
      toast({
        title: "Error fetching discounts.",
        description: "An error occurred while fetching discounts.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  // Fetch discounts on component mount
  useEffect(() => {
    fetchDiscountsData();
  }, [fetchDiscountsData]);

  // Add a new special discount
  const handleAddDiscount = async (newDiscount) => {
    try {
      await addSpecialDiscountApi(newDiscount); // Call API to add discount
      fetchDiscountsData(); // Refresh discounts list
      toast({
        title: "Discount added.",
        description: "The discount has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addDiscountModal.onClose(); // Close the modal
    } catch (error) {
      toast({
        title: "Error adding discount.",
        description: "An error occurred while adding the discount.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Update an existing special discount
  const handleUpdateDiscount = async (updatedDiscount) => {
    try {
      await updateSpecialDiscountApi(updatedDiscount.id, updatedDiscount); // Call API to update discount
      fetchDiscountsData(); // Refresh discounts list
      setEditDiscountData(null); // Close the edit modal
      toast({
        title: "Discount updated.",
        description: "The discount has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating discount.",
        description: "An error occurred while updating the discount.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a special discount
  const handleDeleteDiscount = async (id) => {
    try {
      await deleteSpecialDiscountApi(id); // Call API to delete discount
      fetchDiscountsData(); // Refresh discounts list
      setDeleteDiscountData(null); // Close the delete modal
      toast({
        title: "Discount deleted.",
        description: "The discount has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting discount.",
        description: "An error occurred while deleting the discount.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Handle sorting
  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  // Sort discounts
  const sortedDiscounts = [...discounts].sort((a, b) => {
    if (sortConfig.key) {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }
    return 0;
  });

  // Filter discounts based on selected categories
  const filteredDiscounts = selectedCategories.length > 0
    ? sortedDiscounts.filter((discount) =>
        selectedCategories.includes(discount.user_category)
      )
    : sortedDiscounts;

  // Category options for the multi-select dropdown
  const categoryOptions = [
    { value: "employee", label: "Employee" },
    { value: "farmer", label: "Farmer" },
    { value: "factory", label: "Factory" },
    { value: "guests", label: "Guests" },
  ];

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        {/* Header */}
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Special Discount Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addDiscountModal.onOpen}
          >
            Add Discount
          </Button>
        </Flex>

        {/* Category Filter Dropdown */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <Box width="300px">
            <Select
              isMulti
              options={categoryOptions}
              value={selectedCategories.map((category) => ({
                value: category,
                label: category,
              }))}
              onChange={(selected) =>
                setSelectedCategories(selected.map((option) => option.value))
              }
              placeholder="Filter by category"
              closeMenuOnSelect={false}
            />
          </Box>
        </Flex>

        {/* Discount Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th onClick={() => handleSort("id")}>ID</Th>
                      <Th onClick={() => handleSort("discount_name")}>Discount Name</Th>
                      <Th onClick={() => handleSort("discount_type")}>Discount Type</Th>
                      <Th onClick={() => handleSort("discount_value")}>Discount Value</Th>
                      <Th onClick={() => handleSort("status")}>Status</Th>
                      <Th onClick={() => handleSort("user_category")}>User Category</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredDiscounts.map((discount) => (
                      <Tr key={discount.id}>
                        <Td>{discount.id}</Td>
                        <Td>{discount.discount_name}</Td>
                        <Td>{discount.discount_type}</Td>
                        <Td>{discount.discount_value}</Td>
                        <Td>
                          <Badge colorScheme={discount.status === "active" ? "green" : "red"}>
                            {discount.status}
                          </Badge>
                        </Td>
                        <Td>{discount.user_category}</Td>
                        <Td>
                          <IconButton
                            icon={<MdVisibility />}
                            onClick={() => {
                              setViewDiscountData(discount);
                              viewDiscountModal.onOpen();
                            }}
                            aria-label="View Discount"
                            mr={2}
                            colorScheme="blue"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => setEditDiscountData(discount)}
                            aria-label="Edit Discount"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => setDeleteDiscountData(discount)}
                            aria-label="Delete Discount"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddSpecialDiscount Modal */}
      <AddSpecialDiscount
        isOpen={addDiscountModal.isOpen}
        onClose={addDiscountModal.onClose}
        addNewDiscount={handleAddDiscount}
      />

      {/* EditSpecialDiscount Modal */}
      {editDiscountData && (
        <EditSpecialDiscount
          isOpen={!!editDiscountData}
          onClose={() => setEditDiscountData(null)}
          updateDiscountProp={editDiscountData}
          updateDiscount={handleUpdateDiscount}
        />
      )}

      {/* DeleteSpecialDiscount Modal */}
      {deleteDiscountData && (
        <DeleteSpecialDiscount
          isOpen={!!deleteDiscountData}
          onClose={() => setDeleteDiscountData(null)}
          deleteDiscountProp={deleteDiscountData}
          deleteDiscount={handleDeleteDiscount}
        />
      )}

      {/* ViewDiscount Modal */}
      {viewDiscountData && (
        <Modal isOpen={viewDiscountModal.isOpen} onClose={viewDiscountModal.onClose} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Discount Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Stack spacing={4}>
                <Text><strong>ID:</strong> {viewDiscountData.id}</Text>
                <Text><strong>Discount Name:</strong> {viewDiscountData.discount_name}</Text>
                <Text><strong>Discount Type:</strong> {viewDiscountData.discount_type}</Text>
                <Text><strong>Discount Value:</strong> {viewDiscountData.discount_value}</Text>
                <Text>
                  <strong>Status:</strong>{" "}
                  <Badge colorScheme={viewDiscountData.status === "active" ? "green" : "red"}>
                    {viewDiscountData.status}
                  </Badge>
                </Text>
                <Text><strong>User Category:</strong> {viewDiscountData.user_category}</Text>
                <Text><strong>Conditions:</strong> {viewDiscountData.conditions}</Text>
                <Text><strong>Latitude:</strong> {viewDiscountData.latitude}</Text>
                <Text><strong>Longitude:</strong> {viewDiscountData.longitude}</Text>
                <Text><strong>User Names:</strong> {viewDiscountData.user_names?.join(", ")}</Text>
                <Text><strong>Created At:</strong> {new Date(viewDiscountData.created_at).toLocaleString()}</Text>
                <Text><strong>Modified At:</strong> {new Date(viewDiscountData.modified_at).toLocaleString()}</Text>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button onClick={viewDiscountModal.onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default SpecialDiscountManagement;