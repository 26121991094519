import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const AddPincode = ({ isOpen, onClose, addNewPincode }) => {
  const [newPincode, setNewPincode] = useState({
    code: '',
    status: '',
    city: '',
    godown: '', // Add godown field
  });

  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'code') {
      // Allow only digits and ensure it does not exceed 6 characters
      if (/^\d{0,6}$/.test(value)) {
        setNewPincode({ ...newPincode, [name]: value });
      }
    } else {
      setNewPincode({ ...newPincode, [name]: value });
    }
  };

  const handleSubmit = () => {
    if (newPincode.code && newPincode.status && newPincode.city && newPincode.godown) {
      if (newPincode.code.length !== 6) {
        toast({
          title: 'Error.',
          description: 'Pincode must be exactly 6 digits.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } else {
        addNewPincode(newPincode);
        setNewPincode({ code: '', status: '', city: '', godown: '' }); // Reset form
        onClose();
      }
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Pincode</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="code" mb={3}>
            <FormLabel>Pincode</FormLabel>
            <Input
              type="text"
              name="code"
              value={newPincode.code}
              onChange={handleInputChange}
              placeholder="Enter pincode"
            />
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newPincode.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>

          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newPincode.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysuru">Mysuru</option>
            </Select>
          </FormControl>

          {/* Godown Dropdown */}
          <FormControl id="godown" mb={3}>
            <FormLabel>Godown</FormLabel>
            <Select
              name="godown"
              value={newPincode.godown}
              onChange={handleInputChange}
              placeholder="Select godown"
            >
              <option value="Life9">Life9</option>
              <option value="bannerghatta">bannerghatta</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddPincode;
