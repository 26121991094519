import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
  Image,
  Box,
  IconButton
} from '@chakra-ui/react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { getImageApi } from "networks";

const EditBanner = ({ isOpen, onClose, updateBannerProp, updateBanner }) => {
  const [banner, setBanner] = useState({
    id: '',
    name: '',
    description: '',
    position: '',
    status: '',
    city: '',
    image_path: '', // will hold the preview URL initially
    banner_file: null, // actual file object if new file is selected
  });
  const toast = useToast();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (updateBannerProp) {
      setBanner({
        id: updateBannerProp.id || '',
        name: updateBannerProp.name || '',
        description: updateBannerProp.description || '',
        position: updateBannerProp.position || '',
        status: updateBannerProp.status || '',
        city: updateBannerProp.city || '',
        image_path: updateBannerProp.image_path ? getImageApi(updateBannerProp.image_path) : '',
        banner_file: null,
      });
    }
  }, [updateBannerProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBanner((prev) => ({ ...prev, [name]: value }));
  };

  // Handle file selection; set the file object and preview URL using URL.createObjectURL
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBanner((prev) => ({
        ...prev,
        banner_file: file,
        image_path: URL.createObjectURL(file)
      }));
    }
  };

  // Remove image from state (reset preview and file)
  const removeImage = () => {
    setBanner((prev) => ({ ...prev, image_path: '', banner_file: null }));
  };

  const handleSubmit = () => {
    if (!banner.image_path) {
      toast({
        title: 'Error.',
        description: 'Banner image is mandatory.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (banner.name && banner.description && banner.position && banner.status && banner.city) {
      // Prepare FormData for file upload
      const formData = new FormData();
      formData.append('id', banner.id);
      formData.append('name', banner.name);
      formData.append('description', banner.description);
      formData.append('position', banner.position);
      formData.append('status', banner.status);
      formData.append('city', banner.city);
      if (banner.banner_file) {
        formData.append('banner_file', banner.banner_file);
      } else {
        // If no new file is selected, send the existing image path
        formData.append('image_path', updateBannerProp.image_path);
      }
      updateBanner(formData);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Banner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box
            mb={4}
            position="relative"
            w="150px"
            h="150px"
            mx="auto"
            border="1px dashed"
            borderColor="gray.300"
            borderRadius="md"
            display="flex"
            alignItems="center"
            justifyContent="center"
            cursor="pointer"
            onClick={() => { if (!banner.image_path && fileInputRef.current) fileInputRef.current.click(); }}
          >
            {banner.image_path ? (
              <>
                <Image src={banner.image_path} boxSize="150px" objectFit="cover" alt="Banner Preview" borderRadius="md" />
                <IconButton
                  icon={<FaTimes />}
                  size="sm"
                  colorScheme="red"
                  position="absolute"
                  top="2"
                  right="2"
                  onClick={(e) => { e.stopPropagation(); removeImage(); }}
                  aria-label="Remove image"
                />
              </>
            ) : (
              <FaPlus size="24px" color="gray" />
            )}
          </Box>
          <Input type="file" accept="image/*" display="none" ref={fileInputRef} onChange={handleImageChange} />

          {/* Banner Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Banner Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={banner.name}
              onChange={handleInputChange}
              placeholder="Enter banner name"
            />
          </FormControl>

          {/* Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              name="description"
              value={banner.description}
              onChange={handleInputChange}
              placeholder="Enter banner description"
            />
          </FormControl>

          {/* Position as Dropdown */}
          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Select
              name="position"
              value={banner.position}
              onChange={handleInputChange}
              placeholder="Select position"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={banner.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={banner.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditBanner;
