import React, {  useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Grid,
  Box,
  useToast,
} from "@chakra-ui/react";
import { checkTransactionStatusApi } from "networks"; // Import your API function

const CheckTransaction = ({ isOpen, onClose, transaction }) => {
  const [apiOutput, setApiOutput] = useState(null); // Holds API output
  const [loading, setLoading] = useState(false); // Loading state for API call
  const toast = useToast();

  // Wrapper function to handle modal close
  const handleModalClose = () => {
    setApiOutput(null); // Clear the API output
    onClose(); // Call the original onClose prop
  };

  // Fetch transaction status
  const fetchTransactionStatus = async () => {
    setLoading(true);
    setApiOutput(null); // Clear previous output
    try {
      const response = await checkTransactionStatusApi(transaction);
      setApiOutput(response.data); // Save the API response
    } catch (error) {
      setApiOutput(error.response.data);
      toast({
        title: "Error",
        description: error.response.data.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleModalClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Transaction Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Display transaction details */}
          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Text fontWeight="bold">Transaction ID:</Text>
            <Text>{transaction.transaction_id}</Text>

            <Text fontWeight="bold">User Name:</Text>
            <Text>{transaction.user_name}</Text>

            <Text fontWeight="bold">Payment ID:</Text>
            <Text>{transaction.payment_id}</Text>

            <Text fontWeight="bold">Order ID:</Text>
            <Text>{transaction.order_id}</Text>

            <Text fontWeight="bold">Amount:</Text>
            <Text>{transaction.amount}</Text>

            <Text fontWeight="bold">Transaction Type:</Text>
            <Text>{transaction.transaction_type}</Text>

            <Text fontWeight="bold">Transaction Status:</Text>
            <Text>{transaction.transaction_status}</Text>

            <Text fontWeight="bold">Created At:</Text>
            <Text>{new Date(transaction.created_at).toLocaleString()}</Text>

            <Text fontWeight="bold">Modified At:</Text>
            <Text>{new Date(transaction.modified_at).toLocaleString()}</Text>
          </Grid>

          {/* API Output */}
          {apiOutput && (
            <Box mt={4} p={4} border="1px solid gray" borderRadius="md">
              <Text fontWeight="bold">API Output:</Text>
              <Text>{JSON.stringify(apiOutput, null, 2)}</Text>
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          {!transaction.order_id.toLowerCase().startsWith("w") && (
            <Button
              colorScheme="blue"
              onClick={fetchTransactionStatus}
              isLoading={loading}
              mr={3}
            >
              Check Status
            </Button>
          )}
          <Button onClick={handleModalClose} variant="ghost">
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CheckTransaction;
