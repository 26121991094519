import React from 'react';
import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Card,
  useBreakpointValue,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from '@chakra-ui/react';
import { FaUsers, FaBox, FaTruck, FaWarehouse, FaDollarSign, FaRedo, FaClipboardList, FaClipboardCheck, FaMapMarkerAlt } from 'react-icons/fa';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Dashboard = () => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const cardSize = useBreakpointValue({ base: '100%', md: '20%' });

  // Sample data
  const sampleData = {
    totalUsers: 1500,
    totalBuyers: 500,
    activeUsers: 200,
    products: 350,
    deliveryPersons: {
      available: 20,
      active: 15,
      total: 50,
    },
    totalGodowns: 5,
    todayEarnings: 12000,
    todayReturns: 500,
    todayOrders: 800,
    activeSubscriptions: 150,
    totalOrders: 5000,
    totalSales: 250000,
    totalReturns: 15000,
    totalPincodes: 300,
  };

  const combinedUsers = `${sampleData.activeUsers} / ${sampleData.totalBuyers} / ${sampleData.totalUsers}`;
  const deliveryPersonsCount = `${sampleData.deliveryPersons.available}/${sampleData.deliveryPersons.active}/${sampleData.deliveryPersons.total}`;

  // Random data for orders, sales, and returns over different dates
  const orderData = [
    [Date.UTC(2023, 7, 1), 50],
    [Date.UTC(2023, 7, 2), 100],
    [Date.UTC(2023, 7, 3), 80],
    [Date.UTC(2023, 7, 4), 90],
    [Date.UTC(2023, 7, 5), 120],
  ];

  const salesData = [
    [Date.UTC(2023, 7, 1), 20000],
    [Date.UTC(2023, 7, 2), 30000],
    [Date.UTC(2023, 7, 3), 25000],
    [Date.UTC(2023, 7, 4), 28000],
    [Date.UTC(2023, 7, 5), 35000],
  ];

  const returnsData = [
    [Date.UTC(2023, 7, 1), 500],
    [Date.UTC(2023, 7, 2), 800],
    [Date.UTC(2023, 7, 3), 600],
    [Date.UTC(2023, 7, 4), 700],
    [Date.UTC(2023, 7, 5), 900],
  ];

  const billOptions = {
    chart: { type: 'column' },
    title: { text: 'Orders, Sales, and Returns Over Time' },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: { day: '%e %b', month: '%b \'%y', year: '%Y' },
      title: { text: 'Date' },
    },
    yAxis: {
      title: { text: 'Amount / Orders' },
      labels: { format: '{value:,.0f}' },
      min: 0,
    },
    credits: { enabled: false },
    series: [
      {
        name: 'Orders',
        data: orderData,
        dataLabels: {
          enabled: true,
          format: '{point.y}',
          style: { color: '#000', textOutline: 'none' },
        },
      },
      {
        name: 'Sales (₹)',
        data: salesData,
        dataLabels: {
          enabled: true,
          format: '₹{point.y:,.0f}',
          style: { color: '#28a745', textOutline: 'none' },
        },
      },
      {
        name: 'Returns (₹)',
        data: returnsData,
        dataLabels: {
          enabled: true,
          format: '₹{point.y:,.0f}',
          style: { color: '#dc3545', textOutline: 'none' },
        },
      },
    ],
  };

  // Sample table data with data in brackets
  const tableData = [
    {
      topProducts: 'Product A',
      highestDeliveryPerson: 'Person X (100 deliveries)',
      highestTravelledPerson: 'Person Y (500 km)',
      highestOrderedUser: 'User 1 (50 orders)',
      highestOrderValue: '₹10,000 (User 1)',
      highestReturns: '₹500 (User 1)',
    },
    {
      topProducts: 'Product B',
      highestDeliveryPerson: 'Person X (90 deliveries)',
      highestTravelledPerson: 'Person Z (450 km)',
      highestOrderedUser: 'User 2 (45 orders)',
      highestOrderValue: '₹9,000 (User 2)',
      highestReturns: '₹400 (User 2)',
    },
    {
      topProducts: 'Product C',
      highestDeliveryPerson: 'Person Y (85 deliveries)',
      highestTravelledPerson: 'Person X (400 km)',
      highestOrderedUser: 'User 3 (40 orders)',
      highestOrderValue: '₹8,500 (User 3)',
      highestReturns: '₹300 (User 3)',
    },
    {
      topProducts: 'Product D',
      highestDeliveryPerson: 'Person Z (80 deliveries)',
      highestTravelledPerson: 'Person Y (380 km)',
      highestOrderedUser: 'User 4 (38 orders)',
      highestOrderValue: '₹8,000 (User 4)',
      highestReturns: '₹200 (User 4)',
    },
    {
      topProducts: 'Product E',
      highestDeliveryPerson: 'Person X (75 deliveries)',
      highestTravelledPerson: 'Person X (350 km)',
      highestOrderedUser: 'User 5 (35 orders)',
      highestOrderValue: '₹7,500 (User 5)',
      highestReturns: '₹100 (User 5)',
    },
  ];

  return (
    <Box pt={{ base: '130px', md: '20px', xl: '35px' }} overflowY={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: 'start', md: 'center' }}>
          <Text color={textColor} fontSize={{ base: 'lg', md: '2xl' }} ms="24px" fontWeight="700">Dashboard</Text>
        </Flex>
        <Flex flexDirection="column">
          <Box flex="1" p={4}>
            <Flex justifyContent="space-between" wrap="wrap" mb={8}>
              <Card width="100%" p={4}>
                <Text color={textColor} fontSize={{ base: 'lg', md: 'xl' }} fontWeight="600">Overview</Text>
                <Flex justifyContent="space-between" wrap="wrap">
                  {[
                    { label: 'Users (Active/Buyers/Total)', count: combinedUsers, icon: <FaUsers size={32} color="#0000ff94" /> },
                    { label: 'Total Products', count: sampleData.products, icon: <FaBox size={32} color="green" /> },
                    { label: 'Delivery Persons (Available/Active/Total)', count: deliveryPersonsCount, icon: <FaTruck size={32} color="orange" /> },
                    { label: 'Total Godowns', count: sampleData.totalGodowns, icon: <FaWarehouse size={32} color="red" /> },
                    { label: 'Active Subscriptions', count: sampleData.activeSubscriptions, icon: <FaClipboardCheck size={32} color="green" /> },
                    { label: 'Total Deliverable Pincodes', count: sampleData.totalPincodes, icon: <FaMapMarkerAlt size={32} color="blue" /> },
                    { label: 'Today Orders', count: sampleData.todayOrders, icon: <FaClipboardList size={32} color="teal" /> },
                    { label: "Today's Earnings", count: `₹${sampleData.todayEarnings}`, icon: <FaDollarSign size={32} color="gold" /> },
                    { label: "Today's Returns", count: `₹${sampleData.todayReturns}`, icon: <FaRedo size={32} color="red" /> },
                  ].map(({ label, count, icon }) => (
                    <Stat bg="white" p={4} borderRadius="md" boxShadow="md" m={4} flexBasis={cardSize} key={label}>
                      <StatLabel>{label}</StatLabel>
                      <StatNumber>{count}</StatNumber>
                      {icon}
                    </Stat>
                  ))}
                </Flex>
              </Card>
            </Flex>
         
            <Box mt={8}>
              <Tabs>
                <TabList>
                  <Tab>Weekly</Tab>
                  <Tab>Monthly</Tab>
                  <Tab>3 Months</Tab>
                </TabList>
                <Flex justifyContent="space-between" wrap="wrap" mb={8} mt={8}>
              <Card width="100%" p={4}>
                <Flex justifyContent="space-between" wrap="wrap">
                  {[
                    { label: 'Total Orders', count: sampleData.totalOrders, icon: <FaClipboardList size={32} color="teal" /> },
                    { label: 'Total Sales', count: `₹${sampleData.totalSales}`, icon: <FaDollarSign size={32} color="gold" /> },
                    { label: 'Total Returns', count: `₹${sampleData.totalReturns}`, icon: <FaRedo size={32} color="red" /> },
                  ].map(({ label, count, icon }) => (
                    <Stat bg="white" p={4} borderRadius="md" boxShadow="md" m={4} flexBasis={cardSize} key={label}>
                      <StatLabel>{label}</StatLabel>
                      <StatNumber>{count}</StatNumber>
                      {icon}
                    </Stat>
                  ))}
                </Flex>
              </Card>
            </Flex>
                <TabPanels>
                  {['weekly', 'monthly', '3months'].map(range => (
                    <TabPanel key={range}>
                      <HighchartsReact highcharts={Highcharts} options={billOptions} />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </Box>
            
            {/* Table below the chart */}
            <Box mt={8}>
              <Card width="100%" p={4}>
                <Text color={textColor} fontSize="xl" fontWeight="600" mb={4}>Top Metrics</Text>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Top 5 Products Sold</Th>
                      <Th>Highest Delivery Person</Th>
                      <Th>Highest Travelled Person</Th>
                      <Th>Highest Ordered Users</Th>
                      <Th>Highest Order Value of User</Th>
                      <Th>Highest Returns by User</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {tableData.map((row, index) => (
                      <Tr key={index}>
                        <Td>{row.topProducts}</Td>
                        <Td>{row.highestDeliveryPerson}</Td>
                        <Td>{row.highestTravelledPerson}</Td>
                        <Td>{row.highestOrderedUser}</Td>
                        <Td>{row.highestOrderValue}</Td>
                        <Td>{row.highestReturns}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Card>
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Dashboard;
