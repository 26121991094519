import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';

const EditSpecialDiscount = ({ isOpen, onClose, updateDiscountProp, updateDiscount }) => {
  const [discount, setDiscount] = useState(updateDiscountProp);

  const toast = useToast();

  useEffect(() => {
    setDiscount(updateDiscountProp);
  }, [updateDiscountProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDiscount({ ...discount, [name]: value });
  };

  const handleSubmit = () => {
    if (
      discount.discount_name &&
      discount.discount_type &&
      discount.discount_value
    ) {
      updateDiscount(discount);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all required fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Special Discount</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mb={3}>
            <FormLabel>Discount Name</FormLabel>
            <Input
              type="text"
              name="discount_name"
              value={discount.discount_name}
              onChange={handleInputChange}
              placeholder="Enter discount name"
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Discount Type</FormLabel>
            <Select
              name="discount_type"
              value={discount.discount_type}
              onChange={handleInputChange}
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed Amount</option>
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Discount Value</FormLabel>
            <Input
              type="number"
              name="discount_value"
              value={discount.discount_value}
              onChange={handleInputChange}
              placeholder="Enter discount value"
            />
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={discount.status}
              onChange={handleInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Select>
          </FormControl>

          <FormControl mb={3}>
            <FormLabel>Conditions</FormLabel>
            <Input
              type="text"
              name="conditions"
              value={discount.conditions}
              onChange={handleInputChange}
              placeholder="Enter conditions"
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditSpecialDiscount;