import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Badge,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  List,
  ListItem,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete, MdRemoveRedEye } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddOffer from "../Offers/AddOffer";
import EditOffer from "./EditOffer";
import DeleteOffer from "./DeleteOffer";
import { getOfferApi } from "networks";

const OffersManagement = () => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOfferData, setEditOfferData] = useState(null);
  const [deleteOfferData, setDeleteOfferData] = useState(null);
  const [viewProductsData, setViewProductsData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addOfferModal = useDisclosure();
  const viewProductsModal = useDisclosure();
  const toast = useToast();

  const fetchOffersData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getOfferApi();
      setOffers(response.data.data);
    } catch (error) {
      toast({
        title: "Error fetching offers.",
        description: error?.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  const handleDeleteOffer = (offerId) => {
    setOffers((prevOffers) => prevOffers.filter((offer) => offer.id !== offerId));
  };

  const handleViewProducts = (offer) => {
    setViewProductsData(offer);
    viewProductsModal.onOpen();
  };
  
  useEffect(() => {
    fetchOffersData();
  }, [fetchOffersData]);

  const filteredOffers = offers.filter((offer) =>
    offer?.offer_name?.toLowerCase()?.includes(searchTerm.toLowerCase()) ||
    String(offer?.offer_value || "").includes(searchTerm)
  );

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Offers Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addOfferModal.onOpen}
          >
            Add Offer
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by name or discount..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Offers Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Offer Name</Th>
                      <Th>Coupon Code</Th>
                      <Th>Discount</Th>
                      <Th>Status</Th>
                      <Th>Offer For</Th>
                      <Th>Type</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredOffers.map((offer) => (
                      <Tr key={offer.id}>
                        <Td>{offer.id}</Td>
                        <Td>{offer.offer_name}</Td>
                        <Td>{offer.coupon_code}</Td>
                        <Td>
                          {offer.offer_type === "percentage" 
                            ? `${offer.offer_value}%` 
                            : `₹${offer.offer_value}`}
                        </Td>
                        <Td color={offer.status === "active" ? "green.500" : "red.500"}>
                          {offer.status.charAt(0).toUpperCase() + offer.status.slice(1)}
                        </Td>
                        <Td>{offer.offer_for}</Td>
                        <Td>
                          <Badge 
                            colorScheme={offer.code_or_list === 'code' ? 'blue' : 'purple'}
                            variant="subtle"
                          >
                            {offer.code_or_list === 'code' ? 'Code' : 'List'}
                          </Badge>
                        </Td>
                        <Td>
                          <IconButton
                            icon={<MdRemoveRedEye />}
                            onClick={() => handleViewProducts(offer)}
                            aria-label="View Products"
                            mr={2}
                            colorScheme="blue"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => setEditOfferData(offer)}
                            aria-label="Edit Offer"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => setDeleteOfferData(offer)}
                            aria-label="Delete Offer"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddOffer modal */}
      <AddOffer
        isOpen={addOfferModal.isOpen}
        onClose={addOfferModal.onClose}
        addNewOffer={fetchOffersData} 
      />

      {/* EditOffer modal */}
      {editOfferData && (
        <EditOffer
          isOpen={!!editOfferData}
          onClose={() => setEditOfferData(null)}
          updateOfferProp={editOfferData}
          updateOffer={fetchOffersData}
        />
      )}

      {/* DeleteOffer modal */}
      {deleteOfferData && (
        <DeleteOffer
          isOpen={!!deleteOfferData}
          onClose={() => setDeleteOfferData(null)}
          deleteOfferProp={deleteOfferData}
          deleteOffer={handleDeleteOffer}
        />
      )}

      {/* View Products Modal */}
      <Modal isOpen={viewProductsModal.isOpen} onClose={viewProductsModal.onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Products for Offer: {viewProductsData?.offer_name}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {viewProductsData?.product_names?.length > 0 ? (
              <List spacing={3}>
                {viewProductsData.product_names.map((product, index) => (
                  <ListItem key={index}>
                    <Text fontSize="md">{product}</Text>
                  </ListItem>
                ))}
              </List>
            ) : (
              <Text>No products associated with this offer</Text>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={viewProductsModal.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OffersManagement;