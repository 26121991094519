import React from "react";
import { Icon } from "@chakra-ui/react";
import {
  MdHome,
  MdLocalPharmacy,
  MdList,
  MdViewCarousel,  // Icon for Banners
  MdShoppingCart,
  MdPeople,
  MdLocationCity,
  MdDeliveryDining,
  MdLocalOffer,
  MdTab,  // Icon for Cities
} from "react-icons/md";

const storedUserDataString = sessionStorage.getItem('data');
let SidebarRoutes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/dashboard",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Orders",
    layout: "/admin",
    path: "/ordermanagement",
    icon: <Icon as={MdLocalPharmacy} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Subscriptions",
    layout: "/admin",
    path: "/subscriptions",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Banners",
    layout: "/admin",
    path: "/banners",
    icon: <Icon as={MdViewCarousel} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Categories",
    layout: "/admin",
    path: "/categories",
    icon: <Icon as={MdList} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Products",
    layout: "/admin",
    path: "/products",
    icon: <Icon as={MdShoppingCart} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Customers",
    layout: "/admin",
    path: "/customers",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Pincode",
    layout: "/admin",
    path: "/pincode",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Payment Gateway",
    layout: "/admin",
    path: "/paymentgateway",
    icon: <Icon as={MdLocationCity} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },

  {
    name: "OTA",
    layout: "/admin",
    path: "/ota",
    icon: <Icon as={MdDeliveryDining} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "AI",
    layout: "/admin",
    path: "/ai",
    icon: <Icon as={MdTab} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Offers",
    layout: "/admin",
    path: "/offers",
    icon: <Icon as={MdLocalOffer} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Admins",
    layout: "/admin",
    path: "/tenants",
    icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  },
  {
    name: "Special Discount",
    layout: "/admin",
    path: "/specialdiscount",
    icon: <Icon as={MdLocalOffer} width="20px" height="20px" color="inherit" />,
    sidebar: true,
  }
];

if (storedUserDataString) {
  const storedUserData = JSON.parse(storedUserDataString);
  const userRole = storedUserData.type;

  // Filter routes based on role
  if (userRole === "superadmin") {
    // Show "Dashboard", "AI", "Offers", "Tenants", and "Special Discount" routes for superadmin
    SidebarRoutes = SidebarRoutes.filter((route) => 
      route.name === "Dashboard" || 
      route.name === "AI" || 
      route.name === "Offers" || 
      route.name === "Admins" || 
      route.name === "Special Discount"
    );
  } else if (userRole === "admin") {
    // For admin, show all routes except "Special Discount", "Offers", and "Tenants"
    SidebarRoutes = SidebarRoutes.filter((route) => 
      route.name !== "Special Discount" && 
      route.name !== "Offers" && 
      route.name !== "Admins"
    );
  }
  // Add more roles here if needed
}

export default SidebarRoutes;