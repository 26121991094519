import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Image,
  Box,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { getProductsApi } from "../networks";

const ProductModal = ({ isOpen, onClose, onProductSelect }) => {
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState({});

  useEffect(() => {
    if (isOpen) {
      getProductsApi()
        .then((response) => {
          setProducts(response.data.data || []);
        })
        .catch((error) => console.error("Error fetching products:", error));
    }
  }, [isOpen]);

  const handleSelect = (id, name) => {
    setSelectedProducts((prev) => ({
      ...prev,
      [id]: prev[id] ? undefined : name,
    }));
  };

  const handleSubmit = () => {
    const selectedData = Object.entries(selectedProducts)
      .filter(([id, name]) => name)
      .map(([id, name]) => ({ id, name }));
    onProductSelect(selectedData);
    onClose();
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="4xl" 
      isCentered
      scrollBehavior="inside" // This enables inner scrolling
    >
      <ModalOverlay />
      <ModalContent maxH="85vh"> {/* Limits the maximum height */}
        <ModalHeader position="sticky" top={0} bg="white" zIndex="sticky">
          Available Products
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0} overflowY="auto"> {/* Enables scrolling */}
          {products.length > 0 ? (
            <Table variant="striped" colorScheme="gray" size="md">
              <Thead position="sticky" top={0} bg="white" zIndex="sticky">
                <Tr>
                  <Th>Select</Th>
                  <Th>Image</Th>
                  <Th>Name</Th>
                  <Th>Weight</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products.map((prod) => (
                  <Tr key={prod.id}>
                    <Td>
                      <Checkbox
                        isChecked={!!selectedProducts[prod.id]}
                        onChange={() => handleSelect(prod.id, prod.name)}
                      />
                    </Td>
                    <Td>
                      {prod.image_path?.length > 0 ? (
                        <Image
                          src={`http://localhost:5000/${prod.image_path[0]}`}
                          alt={prod.name}
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="md"
                        />
                      ) : (
                        <Text>N/A</Text>
                      )}
                    </Td>
                    <Td>{prod.name}</Td>
                    <Td>
                      {prod.weight} {prod.weight_unit}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          ) : (
            <Text p={4}>No products available</Text>
          )}
        </ModalBody>
        <ModalFooter position="sticky" bottom={0} bg="white" zIndex="sticky">
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit Selected
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;