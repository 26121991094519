import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  IconButton,
  Spinner,
  useToast,
  Card,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import { getTenantApi, addTenantApi, updateTenantApi, deleteTenantApi } from "networks"; // Import the new APIs
import AddTenantModal from "./AddTenantModal";
import EditTenantModal from "./EditTenantModal";
import DeleteTenantModal from "./DeleteTenantModal";

const TenantManagement = () => {
  const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editTenantData, setEditTenantData] = useState(null);
  const [deleteTenantData, setDeleteTenantData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddTenantModalOpen, setIsAddTenantModalOpen] = useState(false);
  const toast = useToast();

  const fetchTenantsData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getTenantApi();
      if (Array.isArray(response.data.data)) {
        const mappedTenants = response.data.data.map((tenant) => ({
          id: tenant.tenant_id,
          name: tenant.username,
          email: tenant.email,
          phone: tenant.phonenumber,
        }));
        setTenants(mappedTenants);
      } else {
        setTenants([]);
      }
    } catch (error) {
      
      toast({
        title: "Error fetching tenants.",
        description: "An error occurred while fetching tenants.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchTenantsData();
  }, [fetchTenantsData]);

  const handleAddTenant = async (newTenant) => {
    try {
      await addTenantApi(newTenant);
      fetchTenantsData();
      toast({
        title: "Tenant added.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error adding tenant.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleUpdateTenant = async (updatedTenant) => {
    try {
      await updateTenantApi(updatedTenant.id, updatedTenant);
      fetchTenantsData();
      setEditTenantData(null);
      toast({
        title: "Tenant updated.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error updating tenant.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteTenant = async (id) => {
    try {
      await deleteTenantApi(id);
      fetchTenantsData();
      setDeleteTenantData(null);
      toast({
        title: "Tenant deleted.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error deleting tenant.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredTenants = tenants.filter((tenant) =>
    tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box pt="20px" mt="100" overflowY="auto">
      <Flex justifyContent="space-between" alignItems="center" mb="20px">
        <Text fontSize="2xl" fontWeight="700">Tenant Management</Text>
        <Button leftIcon={<MdAdd />} onClick={() => setIsAddTenantModalOpen(true)}>
          Add Roles
        </Button>
      </Flex>

      <InputGroup mb={4}>
        <Input
          placeholder="Search roles..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <InputRightElement>
          <IconButton aria-label="Search" icon={<SearchIcon />} onClick={() => setSearchTerm('')} />
        </InputRightElement>
      </InputGroup>

      <Card>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Name</Th>
                <Th>Email</Th>
                <Th>Phone</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Tr>
                  <Td colSpan={5} textAlign="center"><Spinner /></Td>
                </Tr>
              ) : (
                filteredTenants.map((tenant, index) => (
                  <Tr key={tenant.id}>
                    <Td>{index + 1}</Td>
                    <Td>{tenant.name}</Td>
                    <Td>{tenant.email}</Td>
                    <Td>{tenant.phone}</Td>
                    <Td>
                      <IconButton icon={<MdEdit />} onClick={() => setEditTenantData(tenant)} mr={2} />
                      <IconButton icon={<MdDelete />} onClick={() => setDeleteTenantData(tenant)} colorScheme="red" />
                    </Td>
                  </Tr>
                ))
              )}
            </ Tbody>
          </Table>
        </TableContainer>
      </Card>

      {editTenantData && (
        <EditTenantModal
          isOpen={!!editTenantData}
          onClose={() => setEditTenantData(null)}
          tenantData={editTenantData}
          updateTenant={handleUpdateTenant}
        />
      )}

      {deleteTenantData && (
        <DeleteTenantModal
          isOpen={!!deleteTenantData}
          onClose={() => setDeleteTenantData(null)}
          tenantData={deleteTenantData}
          deleteTenant={handleDeleteTenant}
        />
      )}

      <AddTenantModal
        isOpen={isAddTenantModalOpen}
        onClose={() => setIsAddTenantModalOpen(false)}
        addTenant={handleAddTenant}
      />
    </Box>
  );
};

export default TenantManagement;