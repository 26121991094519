import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
  Box,
  Text,
  RadioGroup,
  Radio,
  HStack,
} from "@chakra-ui/react";
import UserModal from "./UserModal";
import MapComponent from "./MapComponent";

const AddSpecialDiscount = ({ isOpen, onClose, addNewDiscount }) => {
  const [newDiscount, setNewDiscount] = useState({
    discount_name: "",
    discount_type: "",
    discount_value: "",
    status: "active",
    conditions: "",
    latitude: "",
    longitude: "",
    user_ids: [], // Removed user_category
  });

  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDiscount({ ...newDiscount, [name]: value });
  };

  const handleConditionsChange = (value) => {
    setNewDiscount({ ...newDiscount, conditions: value });
  };

  const handleUserSelect = (selectedUsers) => {
    setNewDiscount({
      ...newDiscount,
      user_ids: selectedUsers.map((user) => ({
        id: user.id,
        name: user.name,
        category: user.category,
      })),
    });
  };

  const handleMarkerPlace = ({ lat, lng }) => {
    setNewDiscount({
      ...newDiscount,
      latitude: lat,
      longitude: lng,
    });
  };

  const handleSubmit = () => {
    if (
      newDiscount.discount_name &&
      newDiscount.discount_type &&
      newDiscount.discount_value
    ) {
      // Extract user_category from the first user in user_ids
      const userCategory = newDiscount.user_ids.length > 0 ? newDiscount.user_ids[0].category : "";
  
      // Create the payload
      const payload = {
        discount_name: newDiscount.discount_name,
        discount_type: newDiscount.discount_type,
        discount_value: newDiscount.discount_value,
        status: newDiscount.status,
        conditions: newDiscount.conditions,
        latitude: newDiscount.latitude,
        longitude: newDiscount.longitude,
        user_category: userCategory, // Include user_category
        user_ids: newDiscount.user_ids.map((user) => user.id), // Extract user IDs
      };
  
      // Call the API to add the discount
      addNewDiscount(payload);
  
      // Reset the form
      setNewDiscount({
        discount_name: "",
        discount_type: "",
        discount_value: "",
        status: "active",
        conditions: "",
        latitude: "",
        longitude: "",
        user_ids: [],
      });
  
      // Close the modal
      onClose();
    } else {
      toast({
        title: "Error.",
        description: "Please fill out all required fields.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Special Discount</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={3}>
              <FormLabel>Discount Name</FormLabel>
              <Input
                type="text"
                name="discount_name"
                value={newDiscount.discount_name}
                onChange={handleInputChange}
                placeholder="Enter discount name"
              />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Discount Type</FormLabel>
              <Select
                name="discount_type"
                value={newDiscount.discount_type}
                onChange={handleInputChange}
                placeholder="Select discount type"
              >
                <option value="percentage">Percentage</option>
                <option value="fixed">Fixed Amount</option>
              </Select>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Discount Value</FormLabel>
              <Input
                type="number"
                name="discount_value"
                value={newDiscount.discount_value}
                onChange={handleInputChange}
                placeholder="Enter discount value"
              />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Status</FormLabel>
              <Select
                name="status"
                value={newDiscount.status}
                onChange={handleInputChange}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Select>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Conditions</FormLabel>
              <RadioGroup
                name="conditions"
                value={newDiscount.conditions}
                onChange={handleConditionsChange}
              >
                <HStack spacing="24px">
                  <Radio value="carryforward">Carryforward</Radio>
                  <Radio value="weekly">Weekly</Radio>
                  <Radio value="monthly">Monthly</Radio>
                  <Radio value="forever">Forever</Radio>
                </HStack>
              </RadioGroup>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Select Location</FormLabel>
              <MapComponent onMarkerPlace={handleMarkerPlace} />
              {/* Removed the Text components for latitude and longitude */}
            </FormControl>

            <Button
              colorScheme="teal"
              onClick={() => setIsUserModalOpen(true)}
              mb={3}
            >
              Select Users
            </Button>

            {newDiscount.user_ids.length > 0 && (
              <Box mb={3} p={2} borderWidth="1px" borderRadius="md">
                <Text fontWeight="bold">Selected Users:</Text>
                {newDiscount.user_ids.map((user) => (
                  <Text key={user.id}>
                    - {user.name} (ID: {user.id}, Category: {user.category})
                  </Text>
                ))}
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <UserModal
        isOpen={isUserModalOpen}
        onClose={() => setIsUserModalOpen(false)}
        onUserSelect={handleUserSelect}
      />
    </>
  );
};

export default AddSpecialDiscount;