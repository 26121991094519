import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  Spinner,
  useToast,
  TableContainer,
  IconButton,
  Card,
} from "@chakra-ui/react";
import { MdAdd, MdDelete, MdFileDownload } from "react-icons/md";
import { getOTAApi, deleteOTAApi, getApkFileUrl } from "networks";
import AddOTA from "./AddOTA";
import DeleteOta from "./DeleteOta";

const OtaManagement = () => {
  const [otaList, setOtaList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [deleteOtaData, setDeleteOtaData] = useState(null);
  const toast = useToast();

  const fetchOtaData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getOTAApi();
      setOtaList(res.data.ota);
    } catch (error) {
      toast({
        title: "Error fetching OTA records.",
        description: "An error occurred while fetching OTA records.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchOtaData();
  }, [fetchOtaData]);

  const handleDelete = async (id) => {
    try {
      await deleteOTAApi(id);
      toast({
        title: "Deleted.",
        description: "OTA record deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      fetchOtaData();
      setDeleteOtaData(null);
    } catch (error) {
      toast({
        title: "Error deleting OTA.",
        description: "An error occurred while deleting the OTA record.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const formatOrderDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(dateString).toLocaleString('en-US', options);
  };
  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
       <Flex
                mt={{ base: "20px", md: "45px" }}
                mb="20px"
                justifyContent="space-between"
                alignItems="center"
              >
        <Text fontSize="2xl" fontWeight="bold">OTA Management</Text>
        <Button leftIcon={<MdAdd />} onClick={() => setAddModalOpen(true)}>
          Add OTA
        </Button>
      </Flex>
      {loading ? (
        <Flex justify="center" align="center" height="10vh">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Card width="95%" borderRadius={20}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>ID</Th>
                <Th>Version</Th>
                <Th>Created At</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {otaList.map((ota) => (
                <Tr key={ota.id}>
                  <Td>{ota.id}</Td>
                  <Td>{ota.version}</Td>
                  <Td>{formatOrderDate(ota.created_at)}</Td>
                  <Td>
                    <IconButton
                      aria-label="Download APK"
                      icon={<MdFileDownload />}
                      colorScheme="blue"
                      mr={2}
                      onClick={() => window.open(getApkFileUrl(ota.apk_path), "_blank")}
                    />
                    <IconButton
                      aria-label="Delete OTA"
                      icon={<MdDelete />}
                      colorScheme="red"
                      onClick={() => setDeleteOtaData(ota)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        </Card>
      )}
      <AddOTA
        isOpen={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        refreshOta={fetchOtaData}
      />
      {deleteOtaData && (
        <DeleteOta
          isOpen={!!deleteOtaData}
          onClose={() => setDeleteOtaData(null)}
          otaData={deleteOtaData}
          deleteOta={handleDelete}
        />
      )}
    </Box>
  );
};

export default OtaManagement;
