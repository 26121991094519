import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
} from '@chakra-ui/react';

const EditPincode = ({ isOpen, onClose, updatePincodeProp, updatePincode }) => {
  const [pincode, setPincode] = useState({
    id: '',
    pincode: '',
    status: '',
    city: '',     // Add city field
    godown: '',   // Add godown field
  });

  const [errors, setErrors] = useState({ pincode: '' });

  const toast = useToast();

  useEffect(() => {
    if (updatePincodeProp) {
      setPincode({
        id: updatePincodeProp.id || '',
        pincode: updatePincodeProp.pincode || '',
        status: updatePincodeProp.status || '',
        city: updatePincodeProp.city || '',  // Pre-fill city if available
        godown: updatePincodeProp.godown || '', // Pre-fill godown if available
      });
    }
  }, [updatePincodeProp]);

  const validatePincode = (value) => {
    if (!/^\d{6}$/.test(value)) {
      setErrors({ ...errors, pincode: 'Pincode must be a 6-digit number.' });
    } else {
      setErrors({ ...errors, pincode: '' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'pincode') {
      if (/^\d*$/.test(value)) { // Allow only digits
        setPincode({ ...pincode, [name]: value });
        validatePincode(value); // Validate length and format
      }
    } else {
      setPincode({ ...pincode, [name]: value });
    }
  };

  const handleSubmit = () => {
    if (pincode.pincode && pincode.status && pincode.city && !errors.pincode) {
      updatePincode(pincode); // Pass the entire pincode object, including the id
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields correctly.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Pincode</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="pincode" mb={3} isInvalid={!!errors.pincode}>
            <FormLabel>Pincode</FormLabel>
            <Input
              type="text"
              name="pincode"
              value={pincode.pincode}
              onChange={handleInputChange}
              placeholder="Enter 6-digit pincode"
              maxLength={6} // Enforce max length in UI
            />
            {errors.pincode && (
              <p style={{ color: 'red', fontSize: '12px' }}>{errors.pincode}</p>
            )}
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={pincode.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>

          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={pincode.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysuru">Mysuru</option>
            </Select>
          </FormControl>

          {/* Godown Dropdown */}
          <FormControl id="godown" mb={3}>
            <FormLabel>Godown</FormLabel>
            <Select
              name="godown"
              value={pincode.godown}
              onChange={handleInputChange}
              placeholder="Select godown"
            >
              <option value="Life9">Life9</option>
              <option value="bannerghatta">bannerghatta</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditPincode;
