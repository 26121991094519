import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
  RadioGroup,
  Radio,
  HStack,
  Text,
  Box,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import ProductModal from "../Offers/Products"; // Import the ProductModal
import { addOfferApi } from "../networks";

const AddOffer = ({ isOpen, onClose, addNewOffer }) => {
  const [newOffer, setNewOffer] = useState({
    name: "",
    discountType: "percentage", // Default to percentage
    discountValue: "",
    status: "Active",
    dateRange: [new Date(), new Date()],
    couponCode: "",
    selectedProducts: [],
    offerFor: [], // Array to store selected offer types
    codeOrList: "code", // Default to 'code'
    minimumOrderAmount: "", // Add this line
  });

  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewOffer({ ...newOffer, [name]: value });
  };

  const handleProductSelect = (selectedProducts) => {
      
    setNewOffer({ ...newOffer, selectedProducts });
    setIsProductModalOpen(false);
  };

  const handleOfferForChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setNewOffer({ ...newOffer, offerFor: [...newOffer.offerFor, value] });
    } else {
      setNewOffer({ ...newOffer, offerFor: newOffer.offerFor.filter((item) => item !== value) });
    }
  };

  const handleCodeOrListChange = (value) => {
    setNewOffer({ ...newOffer, codeOrList: value });
  };

  const handleSubmit = async () => {
    if (
      newOffer.name &&
      newOffer.discountValue &&
      newOffer.status &&
      newOffer.dateRange[0] &&
      newOffer.dateRange[1] &&
      newOffer.couponCode &&
      newOffer.offerFor.length > 0
    ) {
      const payload = {
        offer_name: newOffer.name,
        offer_type: newOffer.discountType,
        offer_value: Number(newOffer.discountValue),
        status: newOffer.status.toLowerCase(),
        start_date: newOffer.dateRange[0].toISOString().split("T")[0],
        end_date: newOffer.dateRange[1].toISOString().split("T")[0],
        coupon_code: newOffer.couponCode,
        offer_for: newOffer.offerFor.join(","), // Convert array to comma-separated string
        product_ids: newOffer.selectedProducts.map((prod) => prod.id),
        code_or_list: newOffer.codeOrList, // Add code_or_list to payload
        created_by: "admin",
        minimum_order_amount: Number(newOffer.minimumOrderAmount), // Add this line
      };

      try {
        await addOfferApi(payload);


        toast({
          title: "Success!",
          description: "Offer added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Reset form after submission
        setNewOffer({
          name: "",
          discountType: "percentage",
          discountValue: "",
          status: "Active",
          dateRange: [new Date(), new Date()],
          couponCode: "",
          selectedProducts: [],
          offerFor: [],
          codeOrList: "code", // Reset to default
        });

        // Call the callback function to refresh the offers list
        addNewOffer();

        onClose(); // Close the modal
      } catch (error) {
      
        toast({
          title: "Error!",
          description: "Failed to add offer. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error.",
        description: "Please fill out all fields and select at least one offer type.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Offers</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl id="name" mb={3}>
              <FormLabel>Offer Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={newOffer.name}
                onChange={handleInputChange}
                placeholder="Enter offer name"
              />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Discount Type</FormLabel>
              <Flex gap={4} alignItems="center">
                {/* Percentage Radio Button and Input */}
                <Flex gap={2} alignItems="center">
                  <Radio
                    value="percentage"
                    isChecked={newOffer.discountType === "percentage"}
                    onChange={() =>
                      setNewOffer({ ...newOffer, discountType: "percentage", discountValue: "" })
                    }
                  >
                    Percentage
                  </Radio>
                  <Input
                    type="number"
                    name="discountValue"
                    value={newOffer.discountType === "percentage" ? newOffer.discountValue : ""}
                    onChange={handleInputChange}
                    placeholder="Enter percentage"
                    width="150px"
                    isDisabled={newOffer.discountType !== "percentage"}
                  />
                </Flex>

                {/* Price Radio Button and Input */}
                <Flex gap={2} alignItems="center">
                  <Radio
                    value="price"
                    isChecked={newOffer.discountType === "price"}
                    onChange={() =>
                      setNewOffer({ ...newOffer, discountType: "price", discountValue: "" })
                    }
                  >
                    Price
                  </Radio>
                  <Input
                    type="number"
                    name="discountValue"
                    value={newOffer.discountType === "price" ? newOffer.discountValue : ""}
                    onChange={handleInputChange}
                    placeholder="Enter price"
                    width="150px"
                    isDisabled={newOffer.discountType !== "price"}
                  />
                </Flex>
              </Flex>
            </FormControl>

            <FormControl id="status" mb={3}>
              <FormLabel>Status</FormLabel>
              <Select name="status" value={newOffer.status} onChange={handleInputChange}>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </Select>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Select Date Range</FormLabel>
              <DateRangePicker
                onChange={(range) => setNewOffer({ ...newOffer, dateRange: range })}
                value={newOffer.dateRange}
              />
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Offer For</FormLabel>
              <Flex gap={4}>
                <Checkbox
                  value="Order"
                  isChecked={newOffer.offerFor.includes("Order")}
                  onChange={handleOfferForChange}
                >
                  Order
                </Checkbox>
                <Checkbox
                  value="Subscription"
                  isChecked={newOffer.offerFor.includes("Subscription")}
                  onChange={handleOfferForChange}
                >
                  Subscription
                </Checkbox>
                <Checkbox
                  value="Both"
                  isChecked={newOffer.offerFor.includes("Both")}
                  onChange={handleOfferForChange}
                >
                  Both
                </Checkbox>
              </Flex>
            </FormControl>

            <FormControl mb={3}>
              <FormLabel>Code or List</FormLabel>
              <Flex gap={4}>
                <RadioGroup value={newOffer.codeOrList} onChange={handleCodeOrListChange}>
                  <HStack spacing={4}>
                    <Radio value="code">Code</Radio>
                    <Radio value="list">List</Radio>
                  </HStack>
                </RadioGroup>
              </Flex>
            </FormControl>
            <FormControl id="minimumOrderAmount" mb={3}>
  <FormLabel>Minimum Order Amount</FormLabel>
  <Input
    type="number"
    name="minimumOrderAmount"
    value={newOffer.minimumOrderAmount}
    onChange={(e) => setNewOffer({ ...newOffer, minimumOrderAmount: e.target.value })}
    placeholder="Enter minimum order amount"
  />
</FormControl>

            <Button colorScheme="teal" onClick={() => setIsProductModalOpen(true)}>
              Add Product
            </Button>

            {/* Show selected products */}
            {newOffer.selectedProducts.length > 0 && (
              <Box mb={3} p={2} borderWidth="1px" borderRadius="md">
                <Text fontWeight="bold">Selected Products:</Text>
                {newOffer.selectedProducts.map((prod) => (
                  <Text key={prod.id}>- {prod.name}</Text>
                ))}
              </Box>
            )}

            {/* Coupon Code Field */}
            <FormControl id="couponCode" mt={3}>
              <FormLabel>Coupon Code</FormLabel>
              <Input
                type="text"
                name="couponCode"
                value={newOffer.couponCode}
                onChange={handleInputChange}
                placeholder="Enter coupon code"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" onClick={handleSubmit}>
              Submit Offer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ProductModal
        isOpen={isProductModalOpen}
        onClose={() => setIsProductModalOpen(false)}
        onProductSelect={handleProductSelect}
      />
    </>
  );
};

export default AddOffer;