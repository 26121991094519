import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  useToast,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { getImageApi } from 'networks'; // Added import

const EditCategory = ({ isOpen, onClose, updateCategoryProp, updateCategory }) => {
  const [category, setCategory] = useState({
    id: '',
    name: '',
    city: '',
    position: '',
    status: '',
    image_path: '', // renamed from image_url (for preview URL)
    image_file: null // renamed from category_file
  });
  const toast = useToast();
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (updateCategoryProp) {
      setCategory({
        id: updateCategoryProp.id || '',
        name: updateCategoryProp.name || '',
        position: updateCategoryProp.position || '',
        city: updateCategoryProp.city || '',
        status: updateCategoryProp.status || '',
        image_path: updateCategoryProp.image_path ? getImageApi(updateCategoryProp.image_path) : '',
        image_file: null
      });
    }
  }, [updateCategoryProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategory({ ...category, [name]: value });
  };

  // Handle file selection; set file and preview URL
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCategory(prev => ({
        ...prev,
        image_file: file,
        image_path: URL.createObjectURL(file)
      }));
    }
  };

  // Remove image from state
  const removeImage = () => {
    setCategory(prev => ({ ...prev, image_path: '', image_file: null }));
  };

  // Build FormData and send updated data
  const handleSubmit = () => {
    if (category.name && category.city && category.status) {
      const formData = new FormData();
      formData.append('id', category.id);
      formData.append('name', category.name);
      formData.append('position', category.position);
      formData.append('city', category.city);
      formData.append('status', category.status);
      if (category.image_file) {
        formData.append('image_path', category.image_file);
      } else {
        // If no new file, send the existing image_path
        formData.append('image_path', updateCategoryProp.image_path);
      }
      updateCategory(formData);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => onClose(null)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Category</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Image Preview Box */}
          <div
            style={{
              margin: '0 auto 20px',
              position: 'relative',
              width: '150px',
              height: '150px',
              border: '1px dashed #ccc',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            onClick={() => {
              if (!category.image_path && fileInputRef.current) {
                fileInputRef.current.click();
              }
            }}
          >
            {category.image_path ? (
              <>
                <Image src={category.image_path} boxSize="150px" objectFit="cover" alt="Category Preview" borderRadius="md" />
                <IconButton
                  icon={<FaTimes />}
                  size="sm"
                  colorScheme="red"
                  style={{ position: 'absolute', top: '5px', right: '5px' }}
                  onClick={(e) => { e.stopPropagation(); removeImage(); }}
                  aria-label="Remove image"
                />
              </>
            ) : (
              <FaPlus size={24} color="gray" />
            )}
          </div>
          <Input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          <FormControl id="name" mb={3}>
            <FormLabel>Category Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={category.name}
              onChange={handleInputChange}
              placeholder="Enter category name"
            />
          </FormControl>

          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={category.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
          </FormControl>

          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={category.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={category.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={() => onClose(null)}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditCategory;
