import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility } from "react-icons/md";
import { getUsersApi, updateUserApi, deleteUserApi } from "networks"; // Import your API functions
import NotificationSender from "./Notification";

const CustomerManagement = () => {
  const [userDetails, setUserDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]); // For storing selected users
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  // const [statusFilter, setStatusFilter] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" }); // Sort configuration

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    // Apply sorting whenever sortConfig or userDetails changes
    let sortedUsers = [...userDetails];

    if (sortConfig.key) {
      sortedUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredUsers(sortedUsers);
  }, [sortConfig, userDetails]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getUsersApi();
      setUserDetails(response.data.data);
      setFilteredUsers(response.data.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const handleEditUser = async (user) => {
    console.log("Edit User:", user);
    try {
      const updatedUser = { ...user, status: "Active" };
      await updateUserApi(updatedUser);
      fetchUsers();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  const handleDeleteUser = async (user) => {
    console.log("Delete User:", user);
    try {
      await deleteUserApi(user.id);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: "start", md: "center" }}>
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            User Management
          </Text>
          <NotificationSender users={filteredUsers} />
        </Flex>

        <Flex justifyContent={"center"} mb={5}>
          <Input
            placeholder="Search by User Name or Phone"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            width="40%"
            mr={3}
            backgroundColor={"white"}
          />
          {/* <Select placeholder="Filter by Status" onChange={(e) => setStatusFilter(e.target.value)} width="20%" backgroundColor={"white"}>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
            <option value="pending">Pending</option>
          </Select> */}
        </Flex>

        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={40} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : (
                filteredUsers && filteredUsers.length > 0 ? (
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th onClick={() => handleSort("id")} cursor="pointer">User ID</Th>
                        <Th onClick={() => handleSort("name")} cursor="pointer">User Name</Th>
                        <Th onClick={() => handleSort("phone_number")} cursor="pointer">Phone Number</Th>
                        <Th onClick={() => handleSort("wallet_amount")} cursor="pointer">Total Wallet Amount</Th>
                        <Th>Referred By</Th>
                        <Th onClick={() => handleSort("email")} cursor="pointer">Email</Th>
                        <Th onClick={() => handleSort("created_at")} cursor="pointer">Registration Date</Th>
                        <Th onClick={() => handleSort("status")} cursor="pointer">Status</Th>
                        <Th>Actions</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {filteredUsers.map((user) => (
                        <Tr key={user.id}>
                          <Td>{user.id}</Td>
                          <Td>{user.name}</Td>
                          <Td>{user.phone_number}</Td>
                          <Td>{user.wallet_amount}</Td>
                          <Td>{user.referral_id}</Td>
                          <Td>{user.email}</Td>
                          <Td>{new Date(user.created_at).toLocaleDateString()}</Td>
                          <Td>{user.status}</Td>
                          <Td>
                            <Flex gap="1px">
                              <IconButton icon={<MdVisibility />} aria-label="View User" />
                              <IconButton icon={<MdEdit />} aria-label="Edit User" onClick={() => handleEditUser(user)} />
                              <IconButton icon={<MdDelete />} aria-label="Delete User" onClick={() => handleDeleteUser(user)} />
                            </Flex>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Text>No users available</Text>
                )
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CustomerManagement;
