import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';

const AddBanner = ({ isOpen, onClose, addNewBanner }) => {
  const [newBanner, setNewBanner] = useState({
    name: '',
    description: '',
    position: '',
    status: '',
    city: '',
    banner_file: null,  // store file here
  });

  const toast = useToast();

  // Handle input change for text fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewBanner({ ...newBanner, [name]: value });
  };

  // Handle file change; store the file object directly
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if(file){
      setNewBanner(prev => ({ ...prev, banner_file: file }));
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    if (
      newBanner.name &&
      newBanner.description &&
      newBanner.position &&
      newBanner.banner_file &&
      newBanner.status &&
      newBanner.city
    ) {
      // Create FormData and append fields
      const formData = new FormData();
      formData.append('name', newBanner.name);
      formData.append('description', newBanner.description);
      formData.append('position', newBanner.position);
      formData.append('status', newBanner.status);
      formData.append('city', newBanner.city);
      formData.append('banner_file', newBanner.banner_file);

      addNewBanner(formData);
      setNewBanner({ name: '', description: '', position: '', status: '', city: '', banner_file: null });
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields and upload an image.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Banner</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Banner Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Banner Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={newBanner.name}
              onChange={handleInputChange}
              placeholder="Enter banner name"
            />
          </FormControl>

          {/* Banner Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Input
              type="text"
              name="description"
              value={newBanner.description}
              onChange={handleInputChange}
              placeholder="Enter description"
            />
          </FormControl>

          {/* Banner Position */}
          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Select
              name="position"
              value={newBanner.position}
              onChange={handleInputChange}
              placeholder="Select position"
            >
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </Select>
          </FormControl>

          {/* City Dropdown */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newBanner.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* Banner Image Upload */}
          <FormControl id="bannerFile" mb={3}>
            <FormLabel>Banner Image</FormLabel>
            <Input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              placeholder="Upload banner image"
            />
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newBanner.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        {/* Footer with Submit and Cancel buttons */}
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddBanner;
