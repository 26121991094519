import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

const EditTenantModal = ({ isOpen, onClose, tenantData, updateTenant }) => {
  const [tenant, setTenant] = useState(tenantData);
  const currentUser = "Admin"; // Replace with actual logged-in user

  useEffect(() => {
    if (tenantData) {
      // Initialize tenant state with tenantData and auto-fill 'type' and 'modified_by'
      setTenant({
        ...tenantData,
        type: tenantData.type || "Admin", // Default to "Admin" if not provided
        modified_by: currentUser, // Auto-fill with the current user
      });
    }
  }, [tenantData, currentUser]);

  const handleUpdate = () => {
    // Ensure the payload matches the backend's expectations
    const updatedTenant = {
      id: tenant.id,
      username: tenant.name, // Map 'name' to 'username'
      email: tenant.email,
      phone: tenant.phone,
      company_name: tenant.company_name || "", // Include default value if not provided
      type: tenant.type, // Auto-filled or selected from dropdown
      modified_by: currentUser, // Auto-filled with the current user
    };
    updateTenant(updatedTenant);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Tenant</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <Input
              value={tenant?.name || ""}
              onChange={(e) => setTenant({ ...tenant, name: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              value={tenant?.email || ""}
              onChange={(e) => setTenant({ ...tenant, email: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Phone</FormLabel>
            <Input
              value={tenant?.phone || ""}
              onChange={(e) => setTenant({ ...tenant, phone: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Type</FormLabel>
            <Select
              value={tenant?.type || "Admin"}
              onChange={(e) => setTenant({ ...tenant, type: e.target.value })}
            >
              <option value="Admin">Admin</option>
              <option value="Super Admin">Super Admin</option>
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Modified By</FormLabel>
            <Input
              value={currentUser} // Auto-filled with the current user
              isReadOnly // Make the field read-only
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleUpdate}>Update</Button>
          <Button ml={3} onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditTenantModal;