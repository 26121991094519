import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddBanner from "./AddBanner";
import EditBanner from "./EditBanner";
import DeleteBanner from "./DeleteBanner";
import { getBannersApi, addBannerApi, updateBannerApi, deleteBannerApi } from "networks";
import { getImageApi } from "networks";

const BannerManagement = () => {
  const [banners, setBanners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editBannerData, setEditBannerData] = useState(null);
  const [deleteBannerData, setDeleteBannerData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addBannerModal = useDisclosure();
  const toast = useToast();

  const fetchBannersData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getBannersApi();  // Call API
      setBanners(response.data.data);  // Assuming API returns data in response.data
    } catch (error) {
      toast({
        title: "Error fetching banners.",
        description: "An error occurred while fetching banners.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchBannersData();
    
  }, [fetchBannersData]);

  // Add a new banner
  const handleAddBanner = async (newBanner) => {
    try {
     await addBannerApi(newBanner);  // Call API to add banner
      fetchBannersData()
      toast({
        title: "Banner added.",
        description: "The banner has been added successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      addBannerModal.onClose();
    } catch (error) {
      toast({
        title: "Error adding banner.",
        description: "An error occurred while adding the banner.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Update an existing banner
  const handleUpdateBanner = async (updatedBanner) => {
    try {
     
        await updateBannerApi( updatedBanner);  
      fetchBannersData()
      toast({
        title: "Banner updated.",
        description: "The banner has been updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setEditBannerData(null);
    } catch (error) {
      toast({
        title: "Error updating banner.",
        description: "An error occurred while updating the banner.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Delete a banner
  const handleDeleteBanner = async (id) => {
    try {
      await deleteBannerApi(id); 
      fetchBannersData()
      toast({
        title: "Banner deleted.",
        description: "The banner has been deleted successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setDeleteBannerData(null);
    } catch (error) {
      toast({
        title: "Error deleting banner.",
        description: "An error occurred while deleting the banner.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditBanner = (banner) => {
    setEditBannerData(banner);
  };

  const handleDeleteBannerModal = (banner) => {
    setDeleteBannerData(banner);
  };

  const filteredBanners = banners.filter((banner) =>
    banner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    String(banner.position).includes(searchTerm)
  );
  
  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Banner Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addBannerModal.onOpen}
          >
            Add Banner
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by name or position..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Banner Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>ID</Th>
                      <Th>Name</Th>
                      <Th>Description</Th>
                      <Th>Position</Th>
                      <Th>Image</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredBanners.map((banner) => (
                      <Tr key={banner.id}>
                        <Td>{banner.id}</Td>
                        <Td>{banner.name}</Td>
                        <Td>{banner.description}</Td>
                        <Td>{banner.position}</Td>
                        <Td>
                          <Image
                            src={getImageApi(banner.image_path)}
                            alt={`Banner ${banner.banner_id}`}
                            boxSize="50px"
                            objectFit="cover"
                          />
                        </Td>
                        <Td color={banner.status === "Active" ? "green.500" : "red.500"}>
                          {banner.status}
                        </Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditBanner(banner)}
                            aria-label="Edit Banner"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeleteBannerModal(banner)}
                            aria-label="Delete Banner"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>
      </Flex>

      {/* AddBanner modal */}
      <AddBanner
        isOpen={addBannerModal.isOpen}
        onClose={addBannerModal.onClose}
        addNewBanner={handleAddBanner}
      />

      {/* EditBanner modal */}
      {editBannerData && (
        <EditBanner
          isOpen={!!editBannerData}
          onClose={() => setEditBannerData(null)}
          updateBannerProp={editBannerData}
          updateBanner={handleUpdateBanner}
        />
      )}

      {/* DeleteBanner modal */}
      {deleteBannerData && (
        <DeleteBanner
          isOpen={!!deleteBannerData}
          onClose={() => setDeleteBannerData(null)}
          deleteBannerProp={deleteBannerData}
          deleteBanner={ handleDeleteBanner}
        />
      )}
    </Box>
  );
};

export default BannerManagement;
