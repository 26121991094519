import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  InputGroup,
  InputRightElement,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

const AddTenantModal = ({ isOpen, onClose, addTenant }) => {
  const [tenant, setTenant] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    company_name: "Life9 Systems", // Default company name
    phonenumber: "",
    type: "admin", // Default type
    permissions: {
      edit: false,
      delete: false,
      create: false,
      read: false,
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toast = useToast();

  const handleSubmit = () => {
    if (
      !tenant.username ||
      !tenant.email ||
      !tenant.password ||
      !tenant.confirmPassword ||
      !tenant.company_name ||
      !tenant.phonenumber
    ) {
      toast({
        title: "All fields are required",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (tenant.password !== tenant.confirmPassword) {
      toast({
        title: "Passwords do not match",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    // Send only the necessary fields in the payload
    const { confirmPassword, ...tenantPayload } = tenant;
    addTenant(tenantPayload);
    onClose();
  };

  const handlePermissionChange = (permission) => (e) => {
    setTenant({
      ...tenant,
      permissions: {
        ...tenant.permissions,
        [permission]: e.target.checked,
      },
    });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Roles</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Username</FormLabel>
            <Input onChange={(e) => setTenant({ ...tenant, username: e.target.value })} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input
              autoComplete="off" // Prevent auto-fill
              onChange={(e) => setTenant({ ...tenant, email: e.target.value })}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? "text" : "password"}
                autoComplete="off" // Prevent auto-fill
                onChange={(e) => setTenant({ ...tenant, password: e.target.value })}
              />
              <InputRightElement>
                <Button variant="link" onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Confirm Password</FormLabel>
            <InputGroup>
              <Input
                type={showConfirmPassword ? "text" : "password"}
                onChange={(e) => setTenant({ ...tenant, confirmPassword: e.target.value })}
              />
              <InputRightElement>
                <Button variant="link" onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                  {showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Company Name</FormLabel>
            <Select
              onChange={(e) => setTenant({ ...tenant, company_name: e.target.value })}
              value={tenant.company_name}
            >
              <option value="Life9 Systems">Life9 Systems</option>
              <option value="Farm Connect">Farm Connect</option>
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Phone Number</FormLabel>
            <Input onChange={(e) => setTenant({ ...tenant, phonenumber: e.target.value })} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Type</FormLabel>
            <Select
              onChange={(e) => setTenant({ ...tenant, type: e.target.value })}
              value={tenant.type}
            >
              <option value="admin">Admin</option>
              <option value="superadmin">Superadmin</option>
            </Select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Permissions</FormLabel>
            <Flex gap={4}>
              <Checkbox
                isChecked={tenant.permissions.edit}
                onChange={handlePermissionChange("edit")}
              >
                Edit
              </Checkbox>
              <Checkbox
                isChecked={tenant.permissions.delete}
                onChange={handlePermissionChange("delete")}
              >
                Delete
              </Checkbox>
              <Checkbox
                isChecked={tenant.permissions.create}
                onChange={handlePermissionChange("create")}
              >
                Create
              </Checkbox>
              <Checkbox
                isChecked={tenant.permissions.read}
                onChange={handlePermissionChange("read")}
              >
                Read
              </Checkbox>
            </Flex>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Add
          </Button>
          <Button ml={3} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddTenantModal;