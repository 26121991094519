import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Button,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility, MdMap } from "react-icons/md";
import { getSubscriptionsApi, updateSubscriptionApi,getImageApi } from "networks";
import { format, addDays, subDays } from "date-fns";
import EditSubscription from "./EditSubscription";
import { subscriptionStatusCheck } from "networks";

const SubscriptionManagement = () => {
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [dates, setDates] = useState([]);
  const [editSubscriptionData, setEditSubscriptionData] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchSubscriptions(selectedDate);
    generateDateRange();
  }, [selectedDate]);

  const fetchSubscriptions = async (date) => {
    setLoading(true);
    setFilteredSubscriptions([]);
    try {
      const response = await getSubscriptionsApi({ date });
      if (response.status === 200 && response.data.status === "success") {
        setFilteredSubscriptions(response.data.data);
      } else {
        setFilteredSubscriptions([]);
      }
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    } finally {
      setLoading(false);
    }
  };

  const generateDateRange = () => {
    const today = new Date();
    const start = subDays(today, 3);
    const end = addDays(today, 5);
    const dateArray = [];

    for (let d = start; d <= end; d = addDays(d, 1)) {
      dateArray.push(format(d, "yyyy-MM-dd"));
    }

    setDates(dateArray);
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    fetchSubscriptions(date);
  };

  const handleEditSubscription = (subscription) => {
    setEditSubscriptionData(subscription);
  };

  const handleUpdateSubscription = async (updatedSubscription) => {
    try {
     await updateSubscriptionApi(updatedSubscription);
      fetchSubscriptions(selectedDate);
      setEditSubscriptionData(null);
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to update",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewMap = (latitude, longitude) => {
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, "_blank");
  };
  const handleScheduleChange = async () => {
    await subscriptionStatusCheck()
    fetchSubscriptions(selectedDate);
    generateDateRange();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Scheduled":
        return "blue.500";
      case "Paused":
        return "yellow.500";
      case "Accepted":
        return "purple.500";
      case "Delivering":
        return "cyan.500";
      case "Delivered":
        return "green.500";
      case "Cancelled":
        return "red.500";
      default:
        return "gray.500";
    }
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between">
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            Subscription Management
          </Text>
        </Flex>
        <Flex justifyContent={"flex-end"}>
        <Button onClick={()=>{handleScheduleChange()}}>Check</Button>
        </Flex>
        
        {/* Date Selection */}
        <HStack overflowX="scroll" p={4} spacing={4}>
          {dates.map((date, index) => (
            <Button
              key={index}
              onClick={() => handleDateClick(date)}
              backgroundColor={selectedDate === date ? "teal" : "gray.200"}
              color={selectedDate === date ? "white" : "black"}
            >
              {date === format(new Date(), "yyyy-MM-dd") ? "Today" : format(new Date(date), "EEE, MMM d")}
            </Button>
          ))}
        </HStack>

        {/* Subscription Table */}
        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={10} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : filteredSubscriptions && filteredSubscriptions.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Subscription ID</Th>
                      <Th>Product Image</Th>
                      <Th>Product Name</Th>
                      <Th>Scheduled Quantity</Th>
                      <Th>Scheduled Slot</Th>
                      <Th>Amount</Th>
                      <Th>Delivery Location</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredSubscriptions.map((subscription, index) => (
                      <Tr key={index}>
                        <Td>{subscription.subscription_id}</Td>
                        <Td>
                          <img
                            src={getImageApi(subscription.image_url)}
                            alt={subscription.product_name}
                            width="50"
                            height="50"
                            style={{ borderRadius: "5px" }}
                          />
                        </Td>
                        <Td>{subscription.product_name}</Td>
                        <Td>{subscription.scheduled_quantity}</Td>
                        <Td>{subscription.scheduled_slot}</Td>
                        <Td>Rs {subscription.total.toFixed(2)}</Td>
                        <Td>
                         
                          <Button
                            size="xs"
                            colorScheme="blue"
                            leftIcon={<MdMap />}
                            onClick={() => handleViewMap(subscription.latitude, subscription.longitude)}
                          >
                            View Map
                          </Button>
                        </Td>
                        <Td color={getStatusColor(subscription.status)}>{subscription.status}</Td>
                        <Td>
                          <Flex gap="5px">
                            <IconButton
                              icon={<MdVisibility />}
                              aria-label="View Subscription"
                              onClick={() => console.log("View subscription", subscription)}
                            />
                            <IconButton
                              icon={<MdEdit />}
                              aria-label="Edit Subscription"
                              onClick={() => handleEditSubscription(subscription)}
                            />
                            <IconButton
                              icon={<MdDelete />}
                              aria-label="Delete Subscription"
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No subscriptions available for {selectedDate ? selectedDate : "this date"}</Text>
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>

      {/* Edit Subscription Modal */}
      {editSubscriptionData && (
        <EditSubscription
          isOpen={!!editSubscriptionData}
          onClose={() => setEditSubscriptionData(null)}
          subscriptionData={editSubscriptionData}
          updateSubscription={handleUpdateSubscription}
          selectedDate={selectedDate}
        />
      )}
    </Box>
  );
};

export default SubscriptionManagement;
