import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  RadioGroup,
  Radio,
  Stack,
  HStack, // Added to handle horizontal layout
  Button,
  useToast,
  Box,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { getCategoriesApi, getImageApi } from 'networks'; 

const EditProduct = ({ isOpen, onClose, updateProductProp, updateProduct }) => {
  const [product, setProduct] = useState({
    id: '',
    product_code: '', // Changed productCode to product_code
    name: '',
    position:'',
    category_id: '', // Use category_id for category selection
    price: '',
    city: '',
    cgst: '',
    sgst: '',
    discount: '',
    weight: '', // New weight field
    weight_unit: 'gm', // New unit field for weight
    description: '',
    subscription: 'Both',
    status: '',
  });

  const [categories, setCategories] = useState([]); // State for categories
  const toast = useToast();
  const [images, setImages] = useState(['']); // mix of URL strings (existing images) and File objects for new uploads
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    // Fetch categories when the component loads
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesApi();
        setCategories(response.data.data); // Assuming API returns data in response.data
      } catch (error) {
        toast({
          title: 'Error fetching categories.',
          description: 'An error occurred while fetching categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchCategories();
  }, [toast]);

  useEffect(() => {
    if (updateProductProp) {
      setProduct({
        id: updateProductProp.id || '',
        product_code: updateProductProp.product_code || '',
        name: updateProductProp.name || '',
        position:updateProductProp.position || '',
        category_id: updateProductProp.category_id || '',
        price: updateProductProp.price || '',
        city: updateProductProp.city || '',
        cgst: updateProductProp.cgst || '',
        sgst: updateProductProp.sgst || '',
        discount: updateProductProp.discount || '',
        weight: updateProductProp.weight || '',
        weight_unit: updateProductProp.weight_unit || 'gm', // Set weight unit from updateProductProp
        description: updateProductProp.description || '',
        subscription: updateProductProp.subscription || 'Both',
        status: updateProductProp.status || '',
      });
      // If images are provided in updateProductProp, use them; otherwise start with one empty slot.
      if (updateProductProp.image_path && updateProductProp.image_path.length > 0) {
        setImages(updateProductProp.image_path);
      } else {
        setImages(['']);
      }
    }
  }, [updateProductProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const triggerFileInput = (index) => {
    setCurrentImageIndex(index);
    if (fileInputRef.current) fileInputRef.current.click();
  };

  // Updated: store file objects directly for newly selected images
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && currentImageIndex !== null) {
      setImages((prev) => {
        const copy = [...prev];
        copy[currentImageIndex] = file;
        return copy;
      });
    }
  };

  const removeImage = (index) => {
    setImages((prev) => {
      const copy = [...prev];
      copy[index] = "";
      return copy;
    });
  };

  const addImageBox = () => {
    setImages((prev) => [...prev, ""]);
  };

  const handleSubmit = () => {
    // Prepare FormData instead of JSON payload
    const formData = new FormData();
    formData.append('product_code', product.product_code);
    formData.append('name', product.name);
    formData.append('position', product.position);
    formData.append('category_id', product.category_id);
    formData.append('price', product.price);
    formData.append('city', product.city);
    formData.append('cgst', product.cgst ? product.cgst : "0");
    formData.append('sgst', product.sgst ? product.sgst : "0");
    formData.append('discount', product.discount ? product.discount : "0");
    formData.append('weight', product.weight);
    formData.append('weight_unit', product.weight_unit);
    formData.append('description', product.description);
    formData.append('subscription', product.subscription);
    formData.append('status', product.status);
    // Append the product id to FormData
    formData.append('id', product.id);
    // Append each image file if it's a File (new upload)
    images.forEach((img) => {
      if (img instanceof File) {
        formData.append('product_images', img);
      }
    });
    updateProduct(formData);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Product Code */}
          <FormControl id="product_code" mb={3}>
            <FormLabel>Product Code</FormLabel>
            <Input
              type="text"
              name="product_code"
              value={product.product_code}
              onChange={handleInputChange}
              placeholder="Enter product code"
            />
          </FormControl>

          {/* Product Name */}
          <FormControl id="name" mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={product.name}
              onChange={handleInputChange}
              placeholder="Enter product name"
            />
          </FormControl>

          <FormControl id="position" mb={3}>
            <FormLabel>Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={product.position}
              onChange={handleInputChange}
              placeholder="Enter position"
            />
          </FormControl>

          {/* Category */}
          <FormControl id="category_id" mb={3}>
            <FormLabel>Category</FormLabel>
            <Select
              name="category_id"
              value={product.category_id}
              onChange={handleInputChange}
              placeholder="Select category"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Price */}
          <FormControl id="price" mb={3}>
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              name="price"
              value={product.price}
              onChange={handleInputChange}
              placeholder="Enter price"
            />
          </FormControl>

          {/* City */}
          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={product.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          {/* CGST */}
          <FormControl id="cgst" mb={3}>
            <FormLabel>CGST</FormLabel>
            <Input
              type="number"
              name="cgst"
              value={product.cgst}
              onChange={handleInputChange}
              placeholder="Enter CGST percentage"
            />
          </FormControl>

          {/* SGST */}
          <FormControl id="sgst" mb={3}>
            <FormLabel>SGST</FormLabel>
            <Input
              type="number"
              name="sgst"
              value={product.sgst}
              onChange={handleInputChange}
              placeholder="Enter SGST percentage"
            />
          </FormControl>

          {/* Discount */}
          <FormControl id="discount" mb={3}>
            <FormLabel>Discount</FormLabel>
            <Input
              type="number"
              name="discount"
              value={product.discount}
              onChange={handleInputChange}
              placeholder="Enter discount percentage"
            />
          </FormControl>

          {/* Weight with Unit */}
          <FormControl id="weight" mb={3}>
            <FormLabel>Weight</FormLabel>
            <HStack>
              <Input
                type="text"
                name="weight"
                value={product.weight}
                onChange={handleInputChange}
                placeholder="Enter product weight"
              />
              <Select
                name="weight_unit"
                value={product.weight_unit}
                onChange={handleInputChange}
              >
                <option value="gm">gm</option>
                <option value="ml">ml</option>
              </Select>
            </HStack>
          </FormControl>

          {/* New Images Section */}
          <Box mb={3}>
            <FormLabel>Product Images</FormLabel>
            <Box display="flex" flexWrap="wrap" gap="10px">
              {images.map((img, idx) => (
                <Box
                  key={idx}
                  position="relative"
                  w="150px"
                  h="150px"
                  border="1px dashed"
                  borderColor="gray.300"
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => { if (!img) triggerFileInput(idx) }}
                >
                  {img ? (
                    <>
                      <Image 
                        src={img instanceof File ? URL.createObjectURL(img) : getImageApi(img)}
                        boxSize="150px"
                        objectFit="cover"
                        borderRadius="md" 
                      />
                      <IconButton
                        icon={<FaTimes />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        top="2"
                        right="2"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeImage(idx);
                        }}
                        aria-label="Remove image"
                      />
                    </>
                  ) : (
                    <FaPlus size="24px" color="gray" />
                  )}
                </Box>
              ))}
              <Box
                w="150px"
                h="150px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={addImageBox}
              >
                <FaPlus size="24px" color="gray" />Add Image
              </Box>
            </Box>
          </Box>
          {/* Hidden file input */}
          <Input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            display="none"
            onChange={handleImageChange}
          />

          {/* Description */}
          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={product.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
            />
          </FormControl>

          {/* Subscription */}
          <FormControl as="fieldset" mb={3}>
            <FormLabel as="legend">Subscription</FormLabel>
            <RadioGroup
              name="subscription"
              value={product.subscription}
              onChange={(value) => setProduct({ ...product, subscription: value })}
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Both">Both</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          {/* Status */}
          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={product.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditProduct;
