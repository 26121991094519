import React, { useState } from "react";
import { Box, Textarea, Button, Flex, Card, Spinner } from "@chakra-ui/react";
import { updateAiApi } from "../networks";

const AIManagement = () => {
  const [prompt, setPrompt] = useState("");
  const [output, setOutput] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await updateAiApi({ request: prompt });
      const { result,sql_query } = response.data;
      let formattedResult = "";
      if (Array.isArray(result)) {
        formattedResult = result.map(row => row.join(", ")).join("\n");
      } else {
        formattedResult = "Updated";
      }
      setOutput(`\n${formattedResult}\n\n${sql_query}`);
    } catch (error) {
      setOutput(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={4} mt={150}>
      <Card>
        <Flex mb={4} align="center">
          <Textarea
            placeholder="Enter prompt..."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) { // added onKeyDown handler for Enter key
                e.preventDefault();
                handleSend();
              }
            }}
            resize="vertical"
            mr={2}
          />
          <Button onClick={handleSend} isDisabled={loading}>Send</Button>
          {loading && <Spinner ml={2} />}
        </Flex>
        <Textarea
          placeholder="Output will be shown here..."
          value={output}
          isReadOnly
          resize="vertical"
          height="200px"
        />
      </Card>
    </Box>
  );
};

export default AIManagement;
