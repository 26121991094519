import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react';
import { deleteOfferApi } from '../networks'; // Import the deleteOfferApi function

const DeleteOffer = ({ isOpen, onClose, deleteOfferProp, deleteOffer }) => {
  const toast = useToast();

  const handleDelete = async () => {
    try {
      // Call the deleteOfferApi function
      const response = await deleteOfferApi(deleteOfferProp.id);
      if (response.data.status === 'success') {
        toast({
          title: 'Success!',
          description: 'Offer deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        // Call the deleteOffer function passed as a prop to refresh the offers list
        deleteOffer(deleteOfferProp.id);
      } else {
        toast({
          title: 'Error!',
          description: 'Failed to delete offer. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
     
      toast({
        title: 'Error!',
        description: 'An error occurred while deleting the offer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onClose(); // Close the modal
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Offer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>Are you sure you want to delete this offer?</Text>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={handleDelete}>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteOffer;