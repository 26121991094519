import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Select,
} from '@chakra-ui/react';
import { updateOfferApi } from 'networks'; // Import the API function

const EditOffer = ({ isOpen, onClose, updateOfferProp, updateOffer }) => {
  const [updatedOffer, setUpdatedOffer] = useState(updateOfferProp);
  const toast = useToast();

  useEffect(() => {
    if (updateOfferProp) {
      const formattedOffer = {
        ...updateOfferProp,
        start_date: new Date(updateOfferProp.start_date).toISOString().split('T')[0], // Convert to YYYY-MM-DD
        end_date: new Date(updateOfferProp.end_date).toISOString().split('T')[0], // Convert to YYYY-MM-DD
      };
      setUpdatedOffer(formattedOffer);
    }
  }, [updateOfferProp]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOffer({ ...updatedOffer, [name]: value });
  };

  const handleSubmit = async () => {
    if (
        updatedOffer.offer_name &&
        updatedOffer.offer_type &&
        updatedOffer.offer_value &&
        updatedOffer.status &&
        updatedOffer.start_date &&
        updatedOffer.end_date &&
        updatedOffer.coupon_code
    ) {
        try {
            const payload = {
                id: updatedOffer.id,
                offer_name: updatedOffer.offer_name,
                offer_type: updatedOffer.offer_type,
                offer_value: updatedOffer.offer_value,
                status: updatedOffer.status,
                start_date: updatedOffer.start_date,
                end_date: updatedOffer.end_date,
                coupon_code: updatedOffer.coupon_code,
                modified_by: 'actual_username', // Replace with actual username
            };

              await updateOfferApi(payload);
         

            toast({
                title: 'Success!',
                description: 'Offer updated successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            updateOffer(); // Refresh the offers list
            onClose(); // Close the modal
        } catch (error) {
        
            toast({
                title: 'Error!',
                description: 'Failed to update offer. Please try again.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    } else {
        toast({
            title: 'Error.',
            description: 'Please fill out all fields.',
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    }
};

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Offer</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="offer_name" mb={3}>
            <FormLabel>Offer Name</FormLabel>
            <Input
              type="text"
              name="offer_name"
              value={updatedOffer.offer_name}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="offer_type" mb={3}>
            <FormLabel>Offer Type</FormLabel>
            <Select
              name="offer_type"
              value={updatedOffer.offer_type}
              onChange={handleInputChange}
            >
              <option value="percentage">Percentage</option>
              <option value="price">Fixed Price</option>
            </Select>
          </FormControl>

          <FormControl id="offer_value" mb={3}>
            <FormLabel>
              {updatedOffer.offer_type === 'percentage' ? 'Discount (%)' : 'Discount (₹)'}
            </FormLabel>
            <Input
              type="number"
              name="offer_value"
              value={updatedOffer.offer_value}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={updatedOffer.status}
              onChange={handleInputChange}
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Select>
          </FormControl>

          <FormControl id="start_date" mb={3}>
            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              name="start_date"
              value={updatedOffer.start_date}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="end_date" mb={3}>
            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              name="end_date"
              value={updatedOffer.end_date}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="coupon_code" mb={3}>
            <FormLabel>Coupon Code</FormLabel>
            <Input
              type="text"
              name="coupon_code"
              value={updatedOffer.coupon_code}
              onChange={handleInputChange}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditOffer;