import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Flex,
  Text,
  useColorModeValue,
  IconButton,
  Spinner,
  useToast,
  Card,
  useDisclosure,
  TableContainer,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { MdAdd, MdEdit, MdDelete } from "react-icons/md";
import { SearchIcon } from "@chakra-ui/icons";
import AddPincode from "./AddPincode";
import EditPincode from "./EditPincode";
import DeletePincode from "./DeletePincode";
import { getPincodesApi, updatePincodeApi, deletePincodeApi, addPincodeApi } from "networks";
import moment from "moment";

const PincodeManagement = () => {
  const [pincodes, setPincodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editPincodeData, setEditPincodeData] = useState(null);
  const [deletePincodeData, setDeletePincodeData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const addPincodeModal = useDisclosure();
  const toast = useToast();

  // Fetch pincodes data
  const fetchPincodesData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPincodesApi();
      console.log('API Response:', response); 
      if (response.data && response.data.data) {
        console.log('Received pincodes data:', response.data.data); // Log the actual data
        setPincodes(response.data.data);
      } else {
        console.error('Invalid response structure:', response);
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Full error details:", {
        message: error.message,
        stack: error.stack,
        response: error.response?.data
      });
      toast({
        title: "Error fetching pincodes",
        description: error.message || "Failed to load pincodes",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  // Call API on component mount
  useEffect(() => {
    fetchPincodesData();
  }, [fetchPincodesData]);

  // Group pincodes by pincode value
  const groupedPincodes = pincodes.reduce((acc, pincode) => {
    const pincodeValue = pincode.pincode;
  
    if (!acc[pincodeValue]) {
      acc[pincodeValue] = {
        pincode: pincodeValue,
        routes: [],
        city: pincode.city,
        godown: pincode.godown,
        status: pincode.status,
        createdAt: pincode.createdAt,
        id: pincode.id,
      };
    }
  
    if (pincode.route_city && !acc[pincodeValue].routes.includes(pincode.route_city)) {
      acc[pincodeValue].routes.push(pincode.route_city);
    }
  
    return acc;
  }, {});

  // Add a new pincode
 // Add a new pincode - improved version
const handleAddPincode = async (newPincode) => {
  try {
    const response = await addPincodeApi(newPincode);
    if (response.data?.success) {
      toast({
        title: "Pincode added",
        description: "Pincode has been added successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Immediately update state instead of waiting for refetch
      setPincodes(prev => [...prev, response.data.data]);
      addPincodeModal.onClose();
    } else {
      throw new Error(response.data?.message || "Failed to add pincode");
    }
  } catch (error) {
    console.error("Error adding pincode:", error);
    toast({
      title: "Error adding pincode",
      description: error.message || "Failed to add pincode",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }
};

  // Update an existing pincode
  const handleUpdatePincode = async (updatedPincode) => {
    try {
      const response = await updatePincodeApi(updatedPincode);
      if (response.data && response.data.success) {
        toast({
          title: "Pincode updated",
          description: "Pincode has been updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchPincodesData();
        setEditPincodeData(null);
      } else {
        throw new Error(response.data?.message || "Failed to update pincode");
      }
    } catch (error) {
      console.error("Error updating pincode:", error);
      toast({
        title: "Error updating pincode",
        description: error.message || "Failed to update pincode",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Delete a pincode
  const handleDeletePincode = async (id) => {
    try {
      const response = await deletePincodeApi(id);
      if (response.data && response.data.success) {
        toast({
          title: "Pincode deleted",
          description: "Pincode has been deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchPincodesData();
        setDeletePincodeData(null);
      } else {
        throw new Error(response.data?.message || "Failed to delete pincode");
      }
    } catch (error) {
      console.error("Error deleting pincode:", error);
      toast({
        title: "Error deleting pincode",
        description: error.message || "Failed to delete pincode",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEditPincode = (pincode) => {
    setEditPincodeData({
      ...pincode,
      id: pincode.id
    });
  };

  const handleDeletePincodeModal = (pincode) => {
    setDeletePincodeData(pincode);
  };

  // Filter pincodes based on search term
  const filteredPincodes = Object.values(groupedPincodes).filter((group) => {
    const searchInFields = [
      group.pincode,
      group.status,
      group.city,
      group.godown,
      group.routes.join(", "),
    ];

    return searchInFields.some((field) =>
      field && field.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY="auto">
      <Flex flexDirection="column">
        <Flex
          mt={{ base: "20px", md: "45px" }}
          mb="20px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text color={textColor} fontSize={{ base: "lg", md: "2xl" }} ms="24px" fontWeight="700">
            Pincode Management
          </Text>
          <Button
            leftIcon={<MdAdd />}
            bg="#FFFFFF"
            color="#333"
            _hover={{ bg: "#D3D3D3" }}
            onClick={addPincodeModal.onOpen}
          >
            Add Pincode
          </Button>
        </Flex>

        {/* Search Bar */}
        <Flex justify="flex-end" mt={4} mr={4}>
          <InputGroup>
            <Input
              type="text"
              placeholder="Search by pincode, status, or route..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              backgroundColor="white"
              size="md"
            />
            <InputRightElement>
              <IconButton
                aria-label="Search"
                icon={<SearchIcon />}
                onClick={() => setSearchTerm('')}
                variant="ghost"
                size="md"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>

        {/* Grouped Pincode Table */}
        <Flex justifyContent="center" mt={10}>
          {loading ? (
            <Flex justify="center" align="center" height="10vh">
              <Spinner size="lg" />
            </Flex>
          ) : (
            <Card width="95%" borderRadius={20}>
              <TableContainer>
                <Table variant="simple" size="md">
                  <Thead>
                    <Tr>
                      <Th>SL No.</Th>
                      <Th>Pincode</Th>
                      <Th>City</Th>
                      <Th>Godown</Th>
                      <Th>Status</Th>
                      <Th>Routes</Th>
                      <Th>Created At</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredPincodes.map((pincode, index) => (
                      <Tr key={pincode.id}>
                        <Td>{index + 1}</Td>
                        <Td>{pincode.pincode}</Td>
                        <Td>{pincode.city}</Td>
                        <Td>{pincode.godown}</Td>
                        <Td color={pincode.status === "Active" ? "green.500" : "red.500"}>
                          {pincode.status}
                        </Td>
                        <Td>{pincode.routes.join(", ")}</Td>
                        <Td>{moment(pincode.createdAt).format("YYYY-MM-DD HH:mm")}</Td>
                        <Td>
                          <IconButton
                            icon={<MdEdit />}
                            onClick={() => handleEditPincode(pincode)}
                            aria-label="Edit Pincode"
                            mr={2}
                            colorScheme="teal"
                            size="sm"
                          />
                          <IconButton
                            icon={<MdDelete />}
                            onClick={() => handleDeletePincodeModal(pincode)}
                            aria-label="Delete Pincode"
                            colorScheme="red"
                            size="sm"
                          />
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Flex>

        {/* Modals */}
        <AddPincode
          isOpen={addPincodeModal.isOpen}
          onClose={addPincodeModal.onClose}
          addNewPincode={handleAddPincode}
        />

        {editPincodeData && (
          <EditPincode
            isOpen={Boolean(editPincodeData)}
            onClose={() => setEditPincodeData(null)}
            updatePincodeProp={editPincodeData}
            updatePincode={handleUpdatePincode}
          />
        )}

        {deletePincodeData && (
          <DeletePincode
            isOpen={!!deletePincodeData}
            onClose={() => setDeletePincodeData(null)}
            deletePincodeProp={deletePincodeData}
            deletePincode={handleDeletePincode}
          />
        )}
      </Flex>
    </Box>
  );
};

export default PincodeManagement;