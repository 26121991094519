import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Button,
  Select,
  Textarea,
  useToast,
  RadioGroup,
  Radio,
  Stack,
  HStack,
  Box,
  IconButton,
  Image,
} from '@chakra-ui/react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { getCategoriesApi } from 'networks';

const AddProduct = ({ isOpen, onClose, addNewProduct }) => {
  const [newProduct, setNewProduct] = useState({
    productCode: '', 
    name: '',
    position:'',
    category_id: '', 
    price: '',
    city: '',
    cgst: '',
    sgst: '',
    discount: '',
    description: '',
    weight: '',  
    weight_unit: 'gm',  
    subscription: 'Both', 
    status: '',
  });
  const [categories, setCategories] = useState([]);
  const [images, setImages] = useState(['']); // will store File objects or empty string

  const toast = useToast();
  const fileInputRef = useRef(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesApi();
        setCategories(response.data.data);
      } catch (error) {
        toast({
          title: 'Error fetching categories.',
          description: 'An error occurred while fetching categories.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchCategories();
  }, [toast]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct({ ...newProduct, [name]: value });
  };

  const triggerFileInput = (index) => {
    setCurrentImageIndex(index);
    if (fileInputRef.current) fileInputRef.current.click();
  };

  // Updated: store file objects directly for new images
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && currentImageIndex !== null) {
      setImages((prev) => {
        const copy = [...prev];
        copy[currentImageIndex] = file;
        return copy;
      });
    }
  };

  // Updated removeImage remains unchanged
  const removeImage = (index) => {
    setImages((prev) => {
      const copy = [...prev];
      copy[index] = "";
      return copy;
    });
  };

  const addImageBox = () => {
    setImages((prev) => [...prev, ""]);
  };

  const handleSubmit = () => {
    // Validate required fields including at least one image file
    if (
      newProduct.productCode &&
      newProduct.name &&
      newProduct.position &&
      newProduct.category_id &&
      newProduct.price &&
      newProduct.city &&
      newProduct.cgst &&
      newProduct.sgst &&
      newProduct.discount &&
      newProduct.description &&
      newProduct.weight &&
      newProduct.status &&
      images.filter((img) => img instanceof File).length > 0
    ) {
      const formData = new FormData();
      formData.append('productCode', newProduct.productCode);
      formData.append('name', newProduct.name);
      formData.append('position', newProduct.position);
      formData.append('category_id', newProduct.category_id);
      formData.append('price', newProduct.price);
      formData.append('city', newProduct.city);
      formData.append('cgst', newProduct.cgst);
      formData.append('sgst', newProduct.sgst);
      formData.append('discount', newProduct.discount);
      formData.append('description', newProduct.description);
      formData.append('weight', newProduct.weight);
      formData.append('weight_unit', newProduct.weight_unit);
      formData.append('subscription', newProduct.subscription);
      formData.append('status', newProduct.status);
      // Append each image file
      images.forEach((img) => {
        if (img instanceof File) {
          formData.append('product_images', img);
        }
      });

      addNewProduct(formData);
      // Reset states...
      setNewProduct({
        productCode: '',
        name: '',
        position: '',
        category_id: '',
        price: '',
        city: '',
        cgst: '',
        sgst: '',
        discount: '',
        description: '',
        weight: '',
        weight_unit: 'gm',
        subscription: 'Both',
        status: '',
      });
      setImages(['']);
      onClose();
    } else {
      toast({
        title: 'Error.',
        description: 'Please fill out all fields and upload at least one image.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="productCode" mb={3}>
            <FormLabel>Product Code</FormLabel>
            <Input
              type="text"
              name="productCode"
              value={newProduct.productCode}
              onChange={handleInputChange}
              placeholder="Enter product code"
            />
          </FormControl>

          <FormControl id="name" mb={3}>
            <FormLabel>Product Name</FormLabel>
            <Input
              type="text"
              name="name"
              value={newProduct.name}
              onChange={handleInputChange}
              placeholder="Enter product name"
            />
          </FormControl>
          <FormControl id="position" mb={3}>
            <FormLabel>Product Position</FormLabel>
            <Input
              type="number"
              name="position"
              value={newProduct.position}
              onChange={handleInputChange}
              placeholder="Enter product position"
            />
          </FormControl>

          <FormControl id="category_id" mb={3}>
            <FormLabel>Category</FormLabel>
            <Select
              name="category_id"
              value={newProduct.category_id}
              onChange={handleInputChange}
              placeholder="Select category"
            >
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl id="price" mb={3}>
            <FormLabel>Price</FormLabel>
            <Input
              type="number"
              name="price"
              value={newProduct.price}
              onChange={handleInputChange}
              placeholder="Enter price"
            />
          </FormControl>

          <FormControl id="city" mb={3}>
            <FormLabel>City</FormLabel>
            <Select
              name="city"
              value={newProduct.city}
              onChange={handleInputChange}
              placeholder="Select city"
            >
              <option value="Bengaluru">Bengaluru</option>
              <option value="Mysore">Mysore</option>
            </Select>
          </FormControl>

          <FormControl id="cgst" mb={3}>
            <FormLabel>CGST</FormLabel>
            <Input
              type="number"
              name="cgst"
              value={newProduct.cgst}
              onChange={handleInputChange}
              placeholder="Enter CGST percentage"
            />
          </FormControl>

          <FormControl id="sgst" mb={3}>
            <FormLabel>SGST</FormLabel>
            <Input
              type="number"
              name="sgst"
              value={newProduct.sgst}
              onChange={handleInputChange}
              placeholder="Enter SGST percentage"
            />
          </FormControl>

          <FormControl id="discount" mb={3}>
            <FormLabel>Discount</FormLabel>
            <Input
              type="number"
              name="discount"
              value={newProduct.discount}
              onChange={handleInputChange}
              placeholder="Enter discount percentage"
            />
          </FormControl>

          <FormControl id="weight" mb={3}>
            <FormLabel>Weight</FormLabel>
            <HStack>
              <Input
                type="text"
                name="weight"
                value={newProduct.weight}
                onChange={handleInputChange}
                placeholder="Enter product weight"
              />
              <Select
                name="weight_unit"
                value={newProduct.weight_unit}
                onChange={handleInputChange}
              >
                <option value="gm">gm</option>
                <option value="ml">ml</option>
              </Select>
            </HStack>
          </FormControl>

          <FormControl id="description" mb={3}>
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={newProduct.description}
              onChange={handleInputChange}
              placeholder="Enter product description"
            />
          </FormControl>

          <Box mb={3}>
            <FormLabel>Product Images</FormLabel>
            <Box display="flex" flexWrap="wrap" gap="10px">
              {images.map((img, idx) => (
                <Box
                  key={idx}
                  position="relative"
                  w="150px"
                  h="150px"
                  border="1px dashed"
                  borderColor="gray.300"
                  borderRadius="md"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => { if (!img) triggerFileInput(idx) }}
                >
                  {img ? (
                    <>
                      <Image 
                        src={img instanceof File ? URL.createObjectURL(img) : img}
                        boxSize="150px"
                        objectFit="cover" 
                        borderRadius="md" 
                      />
                      <IconButton
                        icon={<FaTimes />}
                        size="sm"
                        colorScheme="red"
                        position="absolute"
                        top="2"
                        right="2"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeImage(idx);
                        }}
                        aria-label="Remove image"
                      />
                    </>
                  ) : (
                    <FaPlus size="24px" color="gray" />
                  )}
                </Box>
              ))}
              <Box
                w="150px"
                h="150px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={addImageBox}
              >
                <FaPlus size="24px" color="gray" />Add Image
              </Box>
            </Box>
          </Box>
          <Input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            display="none"
            onChange={handleImageChange}
          />

          <FormControl as="fieldset" mb={3}>
            <FormLabel as="legend">Subscription</FormLabel>
            <RadioGroup
              name="subscription"
              value={newProduct.subscription}
              onChange={(value) => setNewProduct({ ...newProduct, subscription: value })}
            >
              <Stack direction="row">
                <Radio value="Yes">Yes</Radio>
                <Radio value="No">No</Radio>
                <Radio value="Both">Both</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>

          <FormControl id="status" mb={3}>
            <FormLabel>Status</FormLabel>
            <Select
              name="status"
              value={newProduct.status}
              onChange={handleInputChange}
              placeholder="Select status"
            >
              <option value="Active">Active</option>
              <option value="Inactive">Inactive</option>
            </Select>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddProduct;
