import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Text,
  Box,
  Select,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import { getUsersApi } from "networks";

const UserModal = ({ isOpen, onClose, onUserSelect }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState({});
  const [userCategories, setUserCategories] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (isOpen) {
      getUsersApi()
        .then((response) => {
          const usersData = response.data.data || [];
          setUsers(usersData);
          setFilteredUsers(usersData);
        })
        .catch((error) => console.error("Error fetching users:", error));
    }
  }, [isOpen]);

  const handleSelect = (id, name) => {
    setSelectedUsers((prev) => ({
      ...prev,
      [id]: prev[id] ? undefined : name,
    }));
  };

  const handleCategoryChange = (userId, category) => {
    setUserCategories((prev) => ({
      ...prev,
      [userId]: category,
    }));
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    const filtered = users.filter((user) =>
      user.phone_number.includes(query)
    );
    setFilteredUsers(filtered);
  };

  const handleSubmit = () => {
    const selectedData = Object.entries(selectedUsers)
      .filter(([id, name]) => name)
      .map(([id, name]) => ({
        id,
        name,
        category: userCategories[id] || "employee",
      }));
    onUserSelect(selectedData);
    onClose();
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose} 
      size="4xl"
      scrollBehavior="inside" // Enable inner scrolling
      isCentered // Center the modal vertically
    >
      <ModalOverlay />
      <ModalContent 
        maxH="90vh" // Limit modal height to 90% of viewport height
        display="flex"
        flexDirection="column"
      >
        <ModalHeader position="sticky" top={0} bg="white" zIndex={1}>
          Select Users
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody 
          p={0} // Remove padding to maximize space
          flex="1"
          overflowY="auto" // Enable vertical scrolling
        >
          {/* Search Input - Sticky at the top */}
          <Box p={4} position="sticky" top={0} bg="white" zIndex={1}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Search by phone number"
                value={searchQuery}
                onChange={handleSearch}
              />
            </InputGroup>
          </Box>

          {/* Table with sticky header */}
          {filteredUsers.length > 0 ? (
            <Box overflowX="auto" h="100%">
              <Table variant="striped" colorScheme="blue">
                <Thead position="sticky" top="60px" bg="white" zIndex={1}>
                  <Tr>
                    <Th>Select</Th>
                    <Th>Name</Th>
                    <Th>Phone Number</Th>
                    <Th>Category</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredUsers.map((user) => (
                    <Tr key={user.id}>
                      <Td>
                        <Checkbox
                          isChecked={!!selectedUsers[user.id]}
                          onChange={() => handleSelect(user.id, user.name)}
                        />
                      </Td>
                      <Td>{user.name}</Td>
                      <Td>{user.phone_number}</Td>
                      <Td>
                        <Select
                          placeholder="Select category"
                          value={userCategories[user.id] || ""}
                          onChange={(e) =>
                            handleCategoryChange(user.id, e.target.value)
                          }
                          isDisabled={!selectedUsers[user.id]}
                        >
                          <option value="employee">Employee</option>
                          <option value="guests">Guests</option>
                          <option value="farmer">Farmer</option>
                          <option value="factory">Factory</option>
                        </Select>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          ) : (
            <Text p={4}>No users available</Text>
          )}
        </ModalBody>
        <ModalFooter position="sticky" bottom={0} bg="white">
          <Button colorScheme="blue" onClick={handleSubmit}>
            Submit Selected
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;