import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
} from "@chakra-ui/react";

const DeleteTenantModal = ({ isOpen, onClose, tenantData, deleteTenant }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete Tenant</ModalHeader>
        <ModalBody>
          <Text>Are you sure you want to delete {tenantData?.name}?</Text>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="red" onClick={() => deleteTenant(tenantData.id)}>Delete</Button>
          <Button ml={3} onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteTenantModal;
