import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Spinner,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Input,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import { MdEdit, MdDelete, MdVisibility, MdMap } from "react-icons/md"; // Import MdMap icon for Map button
import OrderInvoiceView from "./ViewOrder"; // Import the Order view component
import EditOrder from "./EditOrder"; // Import the EditOrder component
import { getOrdersApi, deleteOrderApi } from "networks"; // Import your API functions

const OrderManagement = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState(""); // For search bar
  const [statusFilter, setStatusFilter] = useState(""); // For status dropdown filter
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [editOrderData, setEditOrderData] = useState(null); // Define editOrderData state

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await getOrdersApi(); // Fetch orders using the API
      setOrderDetails(response.data.data); // Assuming `response.data.data` contains the order details
      setFilteredOrders(response.data.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatOrderDate = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Date(dateString).toLocaleString('en-US', options);
  };

  const formatToINR = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Placed":
        return "blue.500"; // Blue for Placed
      case "Accepted":
        return "orange.500"; // Orange for Accepted
      case "Delivering":
        return "yellow.500"; // Yellow for Delivering
      case "Delivered":
        return "green.500"; // Green for Delivered
      case "Cancelled":
        return "red.500"; // Gray for Pending
      default:
        return "gray.500"; // Default color
    }
  };

  useEffect(() => {
    let filtered = orderDetails;
    console.log(searchTerm)
    // Filter by search term (customer name or phone number)
    if (searchTerm) {
      filtered = filtered.filter(
        (order) =>
          order.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          order.phone_number.includes(searchTerm) ||
          order.order_id.toString().includes(searchTerm)
      );
    }

    // Filter by status
    if (statusFilter) {
      filtered = filtered.filter((order) => order.status === statusFilter);
    }

    setFilteredOrders(filtered);
  }, [searchTerm, statusFilter, orderDetails]); // Runs every time searchTerm, statusFilter, or orderDetails changes

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleEditOrder = (order) => {
    setEditOrderData(order);
    onEditOpen(); // Open the edit modal
  };



  const handleDeleteOrder = async (order) => {
    try {
      await deleteOrderApi(order.order_id); // Call API to delete order by order id (adjust if needed)
      fetchOrders(); // Refresh the order list
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    onOpen(); // Open the modal
  };

  const handleViewMap = (order) => {
    const { latitude, longitude } = order; 
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank'); // Open Google Maps in a new window
  };

  return (
    <Box pt={{ base: "130px", md: "20px", xl: "35px" }} overflowY={{ sm: "scroll", lg: "hidden" }}>
      <Flex flexDirection="column">
        <Flex mt="45px" mb="20px" justifyContent="space-between" align={{ base: "start", md: "center" }}>
          <Text fontSize="2xl" ms="24px" fontWeight="700">
            Order Management
          </Text>
        </Flex>

        <Flex justifyContent={"center"} mb={5}>
          <Input
            placeholder="Search by Customer Name or Phone or Order ID"
            value={searchTerm}
            onChange={handleSearchChange}
            width="40%"
            mr={3}
            backgroundColor={"white"}
          />
          <Select placeholder="Filter by Status" onChange={handleStatusFilterChange} width="20%" backgroundColor={"white"}>
            
            <option value="Accepted">Accepted</option>
            <option value="Delivering">Delivering</option>
            <option value="Delivered">Delivered</option>
            <option value="Pending">Pending</option>
          </Select>
        </Flex>

        <Flex justifyContent={"center"} mt={30}>
          <Card width={"97%"} borderRadius={40} bg={"#ededed"}>
            <Box p={5}>
              {loading ? (
                <Flex justify="center" align="center" height="100vh">
                  <Spinner size="xl" />
                </Flex>
              ) : filteredOrders && filteredOrders.length > 0 ? (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Order ID</Th>
                      <Th>Customer Name</Th>
                      <Th>Phone Number</Th>
                      <Th>Total Amount</Th>
                      <Th>Coupon</Th>
                      <Th>Order Date</Th>
                      <Th>Status</Th>
                      <Th>Actions</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {filteredOrders.map((order, index) => (
                      <Tr key={index}>
                        <Td>{order.order_id}</Td>
                        <Td>{order.name}</Td>
                        <Td>{order.phone_number}</Td>
                        <Td>{formatToINR(order.total_amount)}</Td>
                        <Td>{order.coupon}</Td>
                        <Td>{formatOrderDate(order.order_date)}</Td>
                        <Td color={getStatusColor(order.status)} fontWeight="bold">
                          {order.status}
                        </Td>
                        <Td>
                          <Flex gap="1px">
                            <IconButton
                              icon={<MdVisibility />}
                              onClick={() => handleViewOrder(order)}
                              aria-label="View Order"
                            />
                            <IconButton
                              icon={<MdEdit />}
                              onClick={() => handleEditOrder(order)}
                              aria-label="Edit Order"
                            />
                            <IconButton
                              icon={<MdDelete />}
                              onClick={() => handleDeleteOrder(order)}
                              aria-label="Delete Order"
                            />
                            <IconButton
                              icon={<MdMap />} // Add the map icon
                              onClick={() => handleViewMap(order)} // Open map when clicked
                              aria-label="View Map"
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              ) : (
                <Text>No orders available</Text>
              )}
            </Box>
          </Card>
        </Flex>
      </Flex>

      {/* Modal for viewing order details */}
      {selectedOrder && (
        <OrderInvoiceView isOpen={isOpen} onClose={onClose} orderData={selectedOrder} />
      )}

      {/* Edit Order modal */}
      {editOrderData && (
        <EditOrder 
          isOpen={isEditOpen}
          onClose={onEditClose}
          orderData={editOrderData}
          refreshOrders={fetchOrders} // Refresh the orders list after editing
        />
      )}
    </Box>
  );
};

export default OrderManagement;
