import React, { useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

const MapComponent = ({ onMarkerPlace }) => {
  const [markerPosition, setMarkerPosition] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDbqVZQtf2cylUInhdf5M9qdHBLRu1ZLnI", // Replace with your API key
    libraries: ["places"],
  });

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    onMarkerPlace({ lat, lng });
  };

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 12.9716, lng: () => 77.5946 },
      radius: 100 * 1000,
    },
    debounce: 300, // Add debounce to prevent too many API calls
  });

  const handleInputChange = (e) => {
    setValue(e.target.value); // Update the input value
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      setMarkerPosition({ lat, lng });
      onMarkerPlace({ lat, lng });
    } catch (error) {
      console.error("Error selecting address:", error);
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div>
      <div style={{ marginBottom: "16px" }}>
        <input
          type="text"
          value={value}
          onChange={handleInputChange} // Use the custom handler
          disabled={!ready}
          placeholder="Enter an address"
          style={{
            width: "100%",
            padding: "8px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        {status === "OK" && (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              width: "100%",
              backgroundColor: "white",
              border: "1px solid #ddd",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
              maxHeight: "200px",
              overflowY: "auto",
            }}
          >
            {data.map((suggestion) => (
              <div
                key={suggestion.place_id}
                onClick={() => handleSelect(suggestion.description)}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderBottom: "1px solid #eee",
                  ":hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                {suggestion.description}
              </div>
            ))}
          </div>
        )}
      </div>

      <GoogleMap
        zoom={10}
        center={markerPosition || { lat: 12.9716, lng: 77.5946 }}
        mapContainerStyle={{ width: "100%", height: "400px" }}
        onClick={handleMapClick}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
    </div>
  );
};

export default MapComponent;